import React, { Fragment, Suspense } from "react";
import ContentHome from "./ContentHome";
import Loading from '../../Components/Loading'

const FrontendLayout = (props) => (
    <Fragment>
        <Suspense fallback={<Loading />}>
            <ContentHome children={props.children} />
        </Suspense>
    </Fragment>
);

export default FrontendLayout;
