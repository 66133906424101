import * as actions from "../Constants/actionTypes";


export const getStudentsOfCoachFailureAction = (payload) => ({
    type: actions.AT_GET_STUDENTS_OF_COACH_FAILURE,
    payload,
});

export const getStudentsOfCoachSuccessAction = (payload) => ({
    type: actions.AT_GET_STUDENTS_OF_COACH_SUCCESS,
    payload,
});

export const getStudentsOfCoachRequestAction = (payload) => ({
    type: actions.AT_GET_STUDENTS_OF_COACH_REQUEST,
    payload,
});

// DETAIL MEMBER OF COACH
export const getStudentsOfCoachDetailFailureAction = (payload) => ({
    type: actions.AT_GET_STUDENTS_OF_COACH_DETAIL_FAILURE,
    payload,
});

export const getStudentsOfCoachDetailSuccessAction = (payload) => ({
    type: actions.AT_GET_STUDENTS_OF_COACH_DETAIL_SUCCESS,
    payload,
});

export const getStudentsOfCoachDetailRequestAction = (payload) => ({
    type: actions.AT_GET_STUDENTS_OF_COACH_DETAIL_REQUEST,
    payload,
});


// LIST ALL MEMBER OF COACH
export const getAllStudentsOfCoachFailureAction = (payload) => ({
    type: actions.AT_GET_ALL_STUDENTS_OF_COACH_FAILURE,
    payload,
});

export const getAllStudentsOfCoachSuccessAction = (payload) => ({
    type: actions.AT_GET_ALL_STUDENTS_OF_COACH_SUCCESS,
    payload,
});

export const getAllStudentsOfCoachRequestAction = (payload) => ({
    type: actions.AT_GET_ALL_STUDENTS_OF_COACH_REQUEST,
    payload,
});


// LESSON_OF_STUDENT
export const getLessonOfStudentFailureAction = (payload) => ({
    type: actions.AT_GET_LESSON_OF_STUDENT_FAILURE,
    payload,
});

export const getLessonOfStudentSuccessAction = (payload) => ({
    type: actions.AT_GET_LESSON_OF_STUDENT_SUCCESS,
    payload,
});

export const getLessonOfStudentRequestAction = (payload) => ({
    type: actions.AT_GET_LESSON_OF_STUDENT_REQUEST,
    payload,
});



export const getAllStudentsFailureAction = (payload) => ({
    type: actions.AT_GET_ALL_STUDENTS_FAILURE,
    payload,
});

export const getAllStudentsSuccessAction = (payload) => ({
    type: actions.AT_GET_ALL_STUDENTS_SUCCESS,
    payload,
});

export const getAllStudentsRequestAction = (payload) => ({
    type: actions.AT_GET_ALL_STUDENTS_REQUEST,
    payload,
});