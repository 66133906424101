import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

export const getChatThreadDetailApi = (uuid) => {
    return axiosService.getWithToken(`${urlApis.API_CHAT}${uuid}/detail`);
};

export const postCreateMessageApi = (uuid, data, config = {}) => {
    return axiosService.postWithToken(`${urlApis.API_CHAT}${uuid}/message/create`, data, config);
};

export const getNewestUpdateApi = (uuid, params) => {
    return axiosService.getWithToken(`${urlApis.API_CHAT}${uuid}/newest-update?${params}`);
};

export const getMyStudentsApi = () => {
    return axiosService.getWithToken(`${urlApis.API_CHAT_GET_MY_STUDENT}`);
};


export const countUnreadMessageStudentApi = () => {
    return axiosService.getWithToken(`${urlApis.API_COUNT_UNREAD_MESSAGE_STUDENT}`);
};
