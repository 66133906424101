import React from "react";
import { Route } from "react-router";

const FrontendRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            <Layout routeName={rest.name}>
                <Component {...props} />
            </Layout>
        )}
    />
);

export default FrontendRoute;
