import { createStore, applyMiddleware } from "redux";
import rootReducer from "./Reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";
import rootSaga from "./Sagas/rootSaga";
import { STORE_REDUX } from "./../Constants/localStorageConstants";
import { secureStorage } from "./../Utils/localStorageUtils";

function saveToLocalStorage(state) {
    try {
        const serializedState = JSON.stringify(state);
        secureStorage.setItem(STORE_REDUX, serializedState);
    } catch (e) {
        console.log(e);
    }
}

function loadFromLocalStorage() {
    try {
        const serializedState = secureStorage.getItem(STORE_REDUX);
        if (serializedState === null) return undefined;
        return JSON.parse(serializedState);
    } catch (e) {
        console.log(e);
        return undefined;
    }
}

const persistedState = loadFromLocalStorage();
const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
