import React from 'react';
import { connect } from "react-redux";
import { compose } from "redux";
import { find, get, map, isObject } from 'lodash';
import { Link, useRouteMatch }  from 'react-router-dom';
import {
  CBreadcrumb,
  CBreadcrumbItem,
  CSubheader,
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react';
import CIcon from '@coreui/icons-react'


import { ROLES } from "Utils/roleAndPermUtils";
import { PAYMENT_OPTIONS_URL, PAYMENT_OPTIONS_QUERY_PARAMS_KEY } from "Utils/routerConstants"
import { filterValidToken } from "Utils/globalUtils"
import { isMember, isCoach } from "Utils/roleAndPermUtils";
import routes from "./breadcrumbRouter";
import { GOLF_SWING_PAYMENT_ITEMS__MEMBERSHIP_TYPE } from "Utils/constants";

import './TheBreadcrumbs.scss'

// https://github.com/coreui/coreui-free-react-admin-template/blob/v1/React_Full_Project/src/components/Breadcrumb/Breadcrumb.js
const getMatchs = (pathname) => {
  const matchs = ['/'];

  if (pathname === '/') return matchs;

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    matchs.push(currPath);
    return currPath;
  });
  return matchs;
};

const Breadcrumbs = ({ ...props}) => {

  const groups = get(props, 'profile.groups', []);
  const is_member = isMember(groups);
	const is_coach = isCoach(groups);
  const membership_first_time = get(props, 'profile.membership_first_time', false);
  const is_demo_account = get(props, 'profile.is_demo_account', false);

  const match = useRouteMatch();

  const current_path = find(routes, v => {
    if(v.path_check) {
      return v.path_check === match.path
    } else {
      return v.path === match.path
    }
  })
  
  if(!current_path) {
    return null;
  }

  const get_current_path = () => {
    let _current_path = get(current_path, 'path')
    if(is_member) {
      return get(current_path, `path_${ROLES.MEMBER}`, _current_path);
    }
    if(is_coach) {
      return get(current_path, `path_${ROLES.COACH}`, _current_path);
    }
    return _current_path
  }

  const matchs = getMatchs(get_current_path(), get(match, 'params', {}));
  const matchs_params = get(match, 'params', {});

  const findPath = path => item => {
    let _path = get(item, 'path')
    if(is_member) {
      return get(item, `path_${ROLES.MEMBER}`, _path) === path;
    }
    if(is_coach) {
      return get(item, `path_${ROLES.COACH}`, _path) === path;
    }
    return _path === path
  }

  const getRouteName = (route) => {
    const _route_name = get(route, 'name');
    if (isObject(_route_name)) {
      return get(matchs_params, get(_route_name, 'key'), get(_route_name, 'default')).replaceAll('-', ' ')
    }
    return _route_name;
  }

  const renderItems = (path, key) => {
    const _route = find(routes, findPath(path));
    let _new_path = path;
    map(matchs_params, (v, k) => {
      _new_path = _new_path.replace(`:${k}`, v)
    })
    if (_route) {
      return (
        key === matchs.length - 1 ?
          (
            <CBreadcrumbItem key={key} className="text-capitalize" active>{getRouteName(_route)}</CBreadcrumbItem>
          ) :
          (
            <CBreadcrumbItem key={key}>
              {/* <Link to={paths[key] || ''}> */}
              <Link to={_new_path || ''} className="text-capitalize">
                {getRouteName(_route)}
              </Link>
            </CBreadcrumbItem>
          )
      );
    }
    return null;
  }

  const membership_dropdown = () => {
    const _golf_swing_token = get(props, 'profile.profile.golf_swing_token', 0);
    if (is_coach) {
    // if (is_coach || (is_member && !!membership_first_time && _golf_swing_token === 0)) {
      return null;
    }
    const _golf_swing_tokens_user = get(props, 'profile.profile.golf_swing_tokens', []);
    const valid_token_list = _golf_swing_tokens_user.filter(filterValidToken)
	  const membership_token_log = get(props, 'profile.membership_token_log', null);
    const current_package = !!membership_token_log && get(membership_token_log, 'payment_type_obj.type') === GOLF_SWING_PAYMENT_ITEMS__MEMBERSHIP_TYPE && get(membership_token_log, 'payment_type_obj.name')
    const total_lesson_credits = valid_token_list.reduce((a, b) => a + get(b, 'available', 0), 0)

    return (
      <CDropdown inNav className="c-header-nav-item membership-plan-dropdown" direction="down">
        <CDropdownToggle className="c-header-nav-link" caret={false}>
          {!!current_package ? `Membership Plan: ${current_package}` : 'Get membership'}  / {total_lesson_credits} lesson credit{total_lesson_credits > 1 ? 's': ''}
        </CDropdownToggle>
        <CDropdownMenu className="pt-0" placement="bottom-end">
          <CDropdownItem header tag="div" className="membership-plan-dropdown--title">
            Your membership
            <CDropdownItem href="#"><CIcon name="cil-x" /></CDropdownItem>
          </CDropdownItem>
          <CDropdownItem header tag="div" className="membership-plan-dropdown--plan">
            {
              !!current_package ? 
                `${current_package} plan ${!!is_demo_account ? '(trial)' : ''}` : 
                `No membership`
            }
            <Link to={PAYMENT_OPTIONS_URL} className={`btn btn-sm ${!!current_package ? 'btn-success' : 'btn-danger'}`}>
              <CIcon name={!!current_package ? "cil-pen-alt" : "cil-check"}/> {' '}
              {!!current_package ? `Manage` : `Subscribe Now`}
            </Link>
          </CDropdownItem>
          <CDropdownItem header tag="div" className="membership-plan-dropdown--credits">
            <div><strong>Current Lesson credits</strong></div>
            {
              valid_token_list.map((item, key) => <div key={key}>{item.name}<strong>{item.available}</strong></div>)
            }
          </CDropdownItem>
          <CDropdownItem header tag="div" className="membership-plan-dropdown--buy-more">
            <Link to={PAYMENT_OPTIONS_URL} className="btn btn-danger"><CIcon name="cil-cart" /> Buy More Lessons Credits</Link>
            {/* <Link to={`${PAYMENT_OPTIONS_URL}?${PAYMENT_OPTIONS_QUERY_PARAMS_KEY.ACTIVE}=1`} className="btn btn-danger"><CIcon name="cil-cart" /> Buy More Lessons Credits</Link> */}
          </CDropdownItem>
          <CDropdownItem header tag="div" className="membership-plan-dropdown--footer">
            You need lesson credits in order to use this section. 
            Purchase Credits singularly or choose one of our monthly subscriptions!
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
    )
  }


  return (
    <CSubheader className="px-3 justify-content-between">
      <CBreadcrumb className="border-0 c-subheader-nav m-0 px-0 px-md-3">
        {matchs.map(renderItems)}
      </CBreadcrumb>
      <div className="c-subheader-nav">
        {membership_dropdown()}
      </div>
    </CSubheader>
  );
};

const mapStateToProps = (state) => ({
  // state: state,
  isAuthenticated: state.authentication.isAuthenticated,
  // 
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({});

const withCompose = compose(
  connect(mapStateToProps, mapDispatchToProps)
);


export default withCompose(Breadcrumbs);