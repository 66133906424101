import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getLessonSuccessAction,
    getLessonFailureAction,

    getAllLessonCategorySuccessAction,
    getAllLessonCategoryFailureAction,

    getLessonPostSuccessAction,
    getLessonPostFailureAction,

    getExerciseLibraryFailureAction,
    getExerciseLibrarySuccessAction,

    getSevenStageSwingLibraryCategoryFailureAction,
    getSevenStageSwingLibraryCategorySuccessAction,

    getSevenStageSwingLibraryFailureAction,
    getSevenStageSwingLibrarySuccessAction
} from "../Actions/lessonAction";
import { 
    getLessonApi,
    getAlLessonCategoryApi,
    getLessonPostApi,
    getExerciseLibraryApi,
    getSevenStageSwingLibraryApi,
    getSevenStageSwingLibraryCategoryApi
} from "../../Apis/lessonApi";



export default function* mySaga() {
    yield takeLatest(actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_REQUEST, getSevenStageSwingLibraryCategoryRequest);
    yield takeLatest(actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_REQUEST, getSevenStageSwingLibraryRequest);
    yield takeLatest(actions.AT_GET_EXERCISE_LIBRARY_REQUEST, getExerciseLibraryRequest);
    yield takeLatest(actions.AT_GET_LESSON_REQUEST, getLessonRequest);
    yield takeLatest(actions.AT_GET_ALL_LESSON_CATEGORY_REQUEST, getAllLessonCategoryRequest);
    yield takeLatest(actions.AT_GET_LESSON_POST_REQUEST, getLessonPostRequest);
}

function* getSevenStageSwingLibraryRequest({ payload }) {
    const resp = yield call(getSevenStageSwingLibraryApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getSevenStageSwingLibrarySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getSevenStageSwingLibraryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getSevenStageSwingLibraryFailureAction({ error }));
    }
}

function* getSevenStageSwingLibraryCategoryRequest({ payload }) {
    const resp = yield call(getSevenStageSwingLibraryCategoryApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getSevenStageSwingLibraryCategorySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getSevenStageSwingLibraryCategoryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getSevenStageSwingLibraryCategoryFailureAction({ error }));
    }
}

function* getExerciseLibraryRequest({ payload }) {
    const resp = yield call(getExerciseLibraryApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getExerciseLibrarySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getExerciseLibraryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getExerciseLibraryFailureAction({ error }));
    }
}

function* getLessonPostRequest({ payload }) {
    const resp = yield call(getLessonPostApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getLessonPostSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getLessonPostFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getLessonPostFailureAction({ error }));
    }
}

function* getAllLessonCategoryRequest({ payload }) {
    const resp = yield call(getAlLessonCategoryApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getAllLessonCategorySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getAllLessonCategoryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getAllLessonCategoryFailureAction({ error }));
    }
}

function* getLessonRequest({ payload }) {
    const resp = yield call(getLessonApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getLessonSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getLessonFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getLessonFailureAction({ error }));
    }
}
