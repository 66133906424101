import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// payment 
export const getPaymentLogApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_PAYMENT_LOG_LIST}?${data}`);
};

export const getLessonMembershipApi = () => {
    return axiosService.getWithToken(urlApis.API_GET_LESSON_MEMBERSHIP_LIST);
};

export const getPracticeMembershipApi = () => {
    return axiosService.getWithToken(urlApis.API_GET_PRACTICE_MEMBERSHIP_LIST);
};

export const createPaymentLogApi = data => {
    return axiosService.postWithToken(urlApis.API_CREATE_PAYMENT_LOG, data);
};

export const updatePaymentLogApi = (id, data) => {
    return axiosService.putWithToken(`${urlApis.API_CREATE_PAYMENT_LOG}${id}/`, data);
};

export const createAdaptivePaymentsApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_CREATE_PAYMENT_LOG}create-adaptive-payments/`, data);
};
