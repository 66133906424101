import * as actions from "../Constants/actionTypes";
import { AlertError } from "../../Utils/alertUtils";
// import { paginations_default_size } from "../../Utils/globalUtils";

export const default_page_size = 20;

const initState = {
    isLoading: false,
    data: {
        totals: 1,
        page_size: default_page_size,
        current_page: 1,
        next_page: null,
        previous_page: null,
        pages: 1,
        results: []
    },
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.AT_GET_ALL_VIDEO_LIBRARY_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actions.AT_GET_ALL_VIDEO_LIBRARY_SUCCESS: {
            const { totals, page_size } = action.payload;
            return {
                ...state,
                isLoading: false,
                data: {
                    ...action.payload,
                    pages: Math.ceil(totals / default_page_size)
                }
            };
        }
        case actions.AT_GET_ALL_VIDEO_LIBRARY_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false,
            };
        }
        default:
            return state;
    }
};

export default myReducer;
