import React, { Suspense } from "react";
import { Provider } from "react-redux";
//
import store from "../Store/indexStore";

import Loading from '../Components/Loading'
import Main from './Main';

function App() {
    return (
        <Suspense fallback={<Loading />}>
            <Provider store={store}>
                <Main />
            </Provider>
        </Suspense>
    );
}

export default App;
