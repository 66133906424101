import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getAllStudentsOfCoachFailureAction,
    getAllStudentsOfCoachSuccessAction,
    
    getStudentsOfCoachFailureAction,
    getStudentsOfCoachSuccessAction,
    
    getStudentsOfCoachDetailFailureAction,
    getStudentsOfCoachDetailSuccessAction,

    getLessonOfStudentFailureAction,
    getLessonOfStudentSuccessAction,
    
    getAllStudentsFailureAction,
    getAllStudentsSuccessAction,

} from "../Actions/myStudentsAction";
import { 
    getAllStudentsOfCoachListApi,
    getStudentsOfCoachDetailApi,
    getStudentsOfCoachListApi,
    getLessonOfStudentListApi,
    getAllStudentsListApi
} from "../../Apis/myStudentsApi";

export default function* mySaga() {
    yield takeLatest(actions.AT_GET_ALL_STUDENTS_OF_COACH_REQUEST, getAllStudentsOfCoachListRequest);
    yield takeLatest(actions.AT_GET_STUDENTS_OF_COACH_DETAIL_REQUEST, getStudentsOfCoachDetailRequest);
    yield takeLatest(actions.AT_GET_STUDENTS_OF_COACH_REQUEST, getStudentsOfCoachListRequest);
    yield takeLatest(actions.AT_GET_LESSON_OF_STUDENT_REQUEST, getLessonOfStudentRequest);
    yield takeLatest(actions.AT_GET_ALL_STUDENTS_REQUEST, getAllStudentsListRequest);
}

function* getAllStudentsOfCoachListRequest({ payload }) {
    const resp = yield call(getAllStudentsOfCoachListApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getAllStudentsOfCoachSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getAllStudentsOfCoachFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getAllStudentsOfCoachFailureAction({ error }));
    }
}

function* getAllStudentsListRequest({ payload }) {
    const resp = yield call(getAllStudentsListApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getAllStudentsSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getAllStudentsFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getAllStudentsFailureAction({ error }));
    }
}

function* getStudentsOfCoachDetailRequest({ payload }) {
    const resp = yield call(getStudentsOfCoachDetailApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getStudentsOfCoachDetailSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getStudentsOfCoachDetailFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getStudentsOfCoachDetailFailureAction({ error }));
    }
}

function* getStudentsOfCoachListRequest({ payload }) {
    const resp = yield call(getStudentsOfCoachListApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getStudentsOfCoachSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getStudentsOfCoachFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getStudentsOfCoachFailureAction({ error }));
    }
}

function* getLessonOfStudentRequest({ payload }) {
    const resp = yield call(getLessonOfStudentListApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getLessonOfStudentSuccessAction(data));
        } else {
            console.log('data', data)
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getLessonOfStudentFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getLessonOfStudentFailureAction({ error }));
    }
}
