import * as actions from "../Constants/actionTypes";


// get category
export const getAllLessonCategoryFailureAction = (payload) => ({
    type: actions.AT_GET_ALL_LESSON_CATEGORY_FAILURE,
    payload,
});

export const getAllLessonCategorySuccessAction = (payload) => ({
    type: actions.AT_GET_ALL_LESSON_CATEGORY_SUCCESS,
    payload,
});

export const getAllLessonCategoryRequestAction = (payload) => ({
    type: actions.AT_GET_ALL_LESSON_CATEGORY_REQUEST,
    payload,
});


// get lesson list
export const getLessonFailureAction = (payload) => ({
    type: actions.AT_GET_LESSON_FAILURE,
    payload,
});

export const getLessonSuccessAction = (payload) => ({
    type: actions.AT_GET_LESSON_SUCCESS,
    payload,
});

export const getLessonRequestAction = (payload) => ({
    type: actions.AT_GET_LESSON_REQUEST,
    payload,
});


// get lesson post
export const getLessonPostFailureAction = (payload) => ({
    type: actions.AT_GET_LESSON_POST_FAILURE,
    payload,
});

export const getLessonPostSuccessAction = (payload) => ({
    type: actions.AT_GET_LESSON_POST_SUCCESS,
    payload,
});

export const getLessonPostRequestAction = (payload) => ({
    type: actions.AT_GET_LESSON_POST_REQUEST,
    payload,
});





// get ExerciseLibrary
export const getExerciseLibraryFailureAction = (payload) => ({
    type: actions.AT_GET_EXERCISE_LIBRARY_FAILURE,
    payload,
});

export const getExerciseLibrarySuccessAction = (payload) => ({
    type: actions.AT_GET_EXERCISE_LIBRARY_SUCCESS,
    payload,
});

export const getExerciseLibraryRequestAction = (payload) => ({
    type: actions.AT_GET_EXERCISE_LIBRARY_REQUEST,
    payload,
});





// get SevenStageSwingLibrary
export const getSevenStageSwingLibraryCategoryFailureAction = (payload) => ({
    type: actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_FAILURE,
    payload,
});

export const getSevenStageSwingLibraryCategorySuccessAction = (payload) => ({
    type: actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_SUCCESS,
    payload,
});

export const getSevenStageSwingLibraryCategoryRequestAction = (payload) => ({
    type: actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_CATEGORY_REQUEST,
    payload,
});
// ==================
export const getSevenStageSwingLibraryFailureAction = (payload) => ({
    type: actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_FAILURE,
    payload,
});

export const getSevenStageSwingLibrarySuccessAction = (payload) => ({
    type: actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_SUCCESS,
    payload,
});

export const getSevenStageSwingLibraryRequestAction = (payload) => ({
    type: actions.AT_GET_SEVEN_STAGE_SWING_LIBRARY_REQUEST,
    payload,
});