import React, { useEffect } from 'react'
import { get, findIndex } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
  CBadge,
} from '@coreui/react'
import { connect } from "react-redux";
import { compose } from "redux";
import logoImg from "../../../Assets/images/golftec_logo.png"
import logoIcon from "../../../Assets/images/golftec-icon.png"
// sidebar nav config
import navigation from './_nav'
import './TheSidebar.scss';

import { isCoach, isMember } from './../../../Utils/roleAndPermUtils'
import { ROLES } from "../../../Utils/roleAndPermUtils";
import { VIDEO_CATEGORIES_URL, LOGIN_URL, MAIN_PAGE_URL, MAIN_WEBSITE_URL, CHAT } from "../../../Utils/routerConstants";
import CIcon from '@coreui/icons-react';
import { countMessageUnreadRequestAction } from 'Store/Actions/userAction';

const CSidebarNavItemCus = props => {
  return (
    <li className="c-sidebar-nav-item c-sidebar-nav-item-cus">
      <Link className="c-sidebar-nav-link" to={props.to} title={props.name}>
        <div>
          {/* <img src={props.img} height="17px" className="mr-2"/> */}
          {props.name}
        </div>
        {/* <div className="description">{props.description}</div> */}
      </Link>
    </li>
  )
}

const TheSidebar = (props) => {
  const show = props.theme.sidebarShow;
  let _nav = [...navigation.default_nav];

  const profile = get(props, 'profile');
  // let _nav = profile && navigation && navigation.length > 0 && filter(navigation, v => (hasPermissionWithRouter(v.allows, profile)));
  
  const groups = get(props, 'profile.groups', []);
	const chat_thread_id = get(props, 'profile.chat_thread_id', null);
  const unread_message = get(props, 'profile.unread_message', 0);
  const is_member = isMember(groups);


  useEffect(() => {
    let interval = setInterval(() => {
      if (is_member) props.countMessageUnreadRequestAction()
    }, 10000);
    return () => {
        clearInterval(interval);
    };
  }, []);

  const handleLogout = () => {
    props.logout();
    props.setUserProfileToNull();
    props.history.push(MAIN_PAGE_URL)
  };

  const renderNavItems = () => {
    let _nav_with_role = [];
    if (profile && profile.email) {
      _nav_with_role = (profile && profile.groups) && isCoach(profile.groups) ? navigation[ROLES.COACH] : navigation[ROLES.MEMBER];
    }

    // Add chat to nav bar
    let chat_nav = {
      _tag: 'CSidebarNavItem',
        name: 'Chat',
        to: `${CHAT}/${chat_thread_id}`,
        icon: 'cil-chat-bubble',
        className: 'chat-item',
    }
    if (is_member){
      chat_nav = {
        ...chat_nav,
        badge: {
          color: unread_message > 0 ? 'danger' : 'info',
          text: `${unread_message > 10 ? '+10': unread_message }`,
          className: "unread-message-badge"
        }
      }
    }

    _nav = [..._nav, ..._nav_with_role, chat_nav];

    const categories = get(props, 'categories.data');
    let _find_golf_video_index = findIndex(_nav, { id: 'golf_video' });
    if (_find_golf_video_index > -1) {
      let golf_video = [];
      if (categories.length > 0) {
        golf_video = categories.map(v => ({
          _tag: 'CSidebarNavItemCus',
          name: v.name,
          to: `${VIDEO_CATEGORIES_URL}/${v.slug}`,
          img: v.path_icon,
          description: v.description
        }))
      }
      _nav[_find_golf_video_index]._children = golf_video; // set categories in golf video
    }

    return <CCreateElement
      items={_nav}
      components={{
        CSidebarNavDivider,
        CSidebarNavDropdown,
        CSidebarNavItem,
        CSidebarNavItemCus,
        CSidebarNavTitle
      }}
    />
  }

  return (
    <CSidebar
      show={show}
      unfoldable
      onShowChange={(val) => props.setTheme({ type: 'set', sidebarShow: val })}
      className="custom-sidebar"
    >
      <CSidebarBrand className="d-md-down-none" to={MAIN_PAGE_URL}>
        <img src={logoImg} className="c-sidebar-brand-full" height={35} alt="" />
        <img src={logoIcon} className="c-sidebar-brand-minimized" height={35} alt="" />
        {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
      </CSidebarBrand>
      <CSidebarNav>
        {(profile && profile.email) && renderNavItems()}
        {
          (profile && profile.email) ?
            <CSidebarNavItem name={"Sign out"} to={''} icon={'cil-door'} onClick={handleLogout} /> :
            <CSidebarNavItem name={"Login"} to={LOGIN_URL} icon={'cil-door'} />
        }
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

const mapStateToProps = (state) => ({
  // state: state,
  isAuthenticated: state.authentication.isAuthenticated,
  // 
  profile: state.user.profile,
  categories: state.categoriesReducer,
});

const mapDispatchToProps = (dispatch) => ({
  countMessageUnreadRequestAction: () => {
		dispatch(countMessageUnreadRequestAction());
	},
});

const withCompose = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default React.memo(withRouter(withCompose(TheSidebar)))
