import * as URLS from "Utils/routerConstants";
import { ROLES } from "Utils/roleAndPermUtils";

const routes = [
  { path: '/', exact: true, name: 'Home' },


  { path: URLS.DASHBOARD_URL, exact: true, name: 'Dashboard' },


  // Golf Swings
  { path: `${URLS.GOLF_SWINGS_URL}`, exact: true, name: 'Golf Swings Analysis' },
  { 
    path_check: `${URLS.GOLF_SWINGS_URL}/add`, 
    path: `${URLS.LESSON_MANAGE_URL}/:id`, name: 'New Golf Swing' 
  },
  { 
    path_check: `${URLS.GOLF_SWINGS_URL}/:id`, 
    path: `${URLS.LESSON_MANAGE_URL}/:id`, name: 'New Golf Swing' 
  },

  // My Students
  { path: URLS.MY_STUDENT_URL, exact: true, name: 'My Students' },
  { path: URLS.MY_STUDENT__ALL_STUDENTS_URL, name: 'All Students' },
  { path: URLS.MY_STUDENT__ALL_SWINGS_URL, name: 'All Golf swings' },
  { path: `${URLS.MY_STUDENT_URL}/:id`, name: 'Lesson Of Student' },
  { path: `${URLS.MY_STUDENT_SWINGS_URL}/:id`, name: 'Golf swings' },

  // Lessons
  { path: URLS.LESSON_MANAGE_URL, exact: true, name: 'My Golf Lessons' },
  { path: URLS.LESSON_MANAGE_ADD_URL, name: 'New golf lesson' },
  { 
    path_check: `${URLS.LESSON_MANAGE_ADD_URL}/:student_id`, 
    [`path_${ROLES.MEMBER}`]: `${URLS.LESSON_MANAGE_ADD_URL}/:student_id`, 
    path: `${URLS.MY_STUDENT_URL}/:student_id`, 
    name: 'Add golf lesson',
    exact: true,
  },
  { 
    path_check: `${URLS.LESSON_MANAGE_URL}/:slug`,
    path: `${URLS.MY_STUDENT_URL}/:slug`, name: 'Edit golf lesson' 
  },
  { 
    path_check: `${URLS.LESSON_MANAGE_URL}/view/:lessonSlug`,
    [`path_${ROLES.MEMBER}`]: `${URLS.LESSON_MANAGE_URL}/view/:lessonSlug`, 
    path: `${URLS.MY_STUDENT_URL}/:lessonSlug`,
    name: 'Detail golf lesson' 
  },

  // Golf Video
  { path: `${URLS.VIDEO_CATEGORIES_URL}`, exact: true, name: 'Golf Video' },
  { path: `${URLS.VIDEO_CATEGORIES_URL}/:slug/:videoSlug?`, name: {key: 'slug', default: 'Detail'} },

  // Image Library
  { path: `${URLS.IMAGES_LIBRARY_URL}`, exact: true, name: 'Image Library' },
  { path: `${URLS.IMAGES_LIBRARY_URL}/add`, name: 'Add New Image' },
  { path: `${URLS.IMAGES_LIBRARY_URL}/:id`, name: 'Edit Image' },

  // Video Library
  { path: `${URLS.VIDEOS_LIBRARY_URL}`, exact: true, name: 'Videos Library' },
  { path: `${URLS.VIDEOS_LIBRARY_URL}/add`, name: 'Add New Video' },
  { path: `${URLS.VIDEOS_LIBRARY_URL}/:id`, name: 'Edit Video' },

  // Exercise Library
  { path: `${URLS.EXERCISE_LIBRARY_URL}`, exact: true, name: 'Exercise Library' },
  { path: `${URLS.EXERCISE_LIBRARY_URL}/add`, name: 'Form' },
  { path: `${URLS.EXERCISE_LIBRARY_URL}/edit/:slug`, name: 'Form' },
  { 
    path_check: `${URLS.EXERCISE_LIBRARY_URL}/:slug/:detailSlug`, 
    path: `${URLS.EXERCISE_LIBRARY_URL}/:slug`,
    name: 'Detail' 
  },
  { path: `${URLS.EXERCISE_LIBRARY_URL}/:slug`, name: 'Detail' },

  // My Account
  { path: `${URLS.PROFILE_URL}`, name: 'Profile' },
  { path: `${URLS.CHANGE_PASSWORD_URL}`, name: 'Change password' },
  { path: `${URLS.PAYMENT_HISTORY_URL}`, name: 'Payment History' },
  { path: `${URLS.PAYMENT_HISTORY_FOR_COACH_URL}`, name: 'Payment History' },
  { path: `${URLS.PAYMENT_HISTORY_VIDEO_COURSE_URL}`, name: 'Payment History' },
  { path: URLS.PAYMENT_OPTIONS_URL, name: 'Membership' },
  { path: URLS.BILLING_PAYMENT_URL, name: 'Billing & Payments' },
  { path: `${URLS.EXERCISE_LIBRARY_URL}/:slug`, name: 'Detail' },

  // REPORTS
  { path: URLS.REPORTS_URL, name: 'Report' },
  { path: URLS.REPORTS__NUMBER_OF_LESSON_GIVEN_URL, name: 'Reports - Number of Lessons Given' },
  { path: `${URLS.REPORTS__NUMBER_OF_LESSON_GIVEN_URL}/:coachId`, name: 'Lesson Of Coach' },
  { path: URLS.REPORTS__REVENUA_BROKEN_DOWN_BY_PRODUCT_URL, name: 'Reports - Revenue Broken Down by Product' },
  { path: URLS.REPORTS__USERS_CANCELLED_SUBSCRIPTION_URL, name: 'Reports - Users Cancelled Subscription' },
  { path: URLS.REPORTS__AT_RISK_REPORT_URL, name: 'Reports - At Risk Report' },
  { path: URLS.REPORTS__PAYMENT_LOGS_URL, name: 'Reports - Payment Logs' },
  { path: URLS.REPORTS__PAGEVIEWS_URL, name: 'Reports - Pageviews' },
]

export default routes;
