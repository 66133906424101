import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import { VIDEOS_LIBRARY_URL } from "../../../Utils/routerConstants";

import BackendLayout from "../../../Backend/Layout/BackendLayout";
import BackendLayoutNoContent from "../../../Backend/Layout/BackendLayoutNoContent";

// Images Library
// import LibraryPage from "./index";
// import LibraryList from "./LibraryList";
const LibraryPage = lazy(() => import('./index'));
const LibraryList = lazy(() => import('./LibraryList'));
const LibraryFormPage = lazy(() => import('./form'));


const routers = [
  // Library for coach only
  {
    path: "/ckeditor/videos-library",
    name: "Ckeditor Library",
    component: LibraryList,
    layout: BackendLayoutNoContent,
    allows: [ROLES.COACH],
  },
  {
    path: `${VIDEOS_LIBRARY_URL}/add`,
    name: "Add New Video",
    component: LibraryFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: `${VIDEOS_LIBRARY_URL}/:id`,
    name: "Edit Video",
    component: LibraryFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: VIDEOS_LIBRARY_URL,
    name: "Videos",
    component: LibraryPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  }
];

export default routers;