import * as actions from "../Constants/actionTypes";


// get image
export const getImageLibraryFailureAction = (payload) => ({
    type: actions.AT_GET_IMAGE_LIBRARY_FAILURE,
    payload,
});

export const getImageLibrarySuccessAction = (payload) => ({
    type: actions.AT_GET_IMAGE_LIBRARY_SUCCESS,
    payload,
});

export const getImageLibraryRequestAction = (payload) => ({
    type: actions.AT_GET_IMAGE_LIBRARY_REQUEST,
    payload,
});

// get all video
export const getAllVideoLibraryFailureAction = (payload) => ({
    type: actions.AT_GET_ALL_VIDEO_LIBRARY_FAILURE,
    payload,
});

export const getAllVideoLibrarySuccessAction = (payload) => ({
    type: actions.AT_GET_ALL_VIDEO_LIBRARY_SUCCESS,
    payload,
});

export const getAllVideoLibraryRequestAction = (payload) => ({
    type: actions.AT_GET_ALL_VIDEO_LIBRARY_REQUEST,
    payload,
});
