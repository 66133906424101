import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash"

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "Constants/statusCodeApi";
import {
    getPaymentLogSuccessAction,
    getPaymentLogFailureAction,

    getPracticeMembershipFailureAction,
    getPracticeMembershipSuccessAction,

    getLessonMembershipFailureAction,
    getLessonMembershipSuccessAction
} from "../Actions/paymentAction";
import { 
    // getPaymentLogApi,
    getPracticeMembershipApi,
    getLessonMembershipApi
} from "Apis/paymentApi";
import { 
    getPaymentLogApi,
} from "Apis/golfSwingApi";

export default function* paymentSaga() {
    yield takeLatest(actions.AT_GET_PAYMENT_LOG_REQUEST, getPaymentLogRequest);
    yield takeLatest(actions.AT_GET_PRACTICE_MEMBERSHIP_REQUEST, getPracticeMembershipRequest);
    yield takeLatest(actions.AT_GET_LESSON_MEMBERSHIP_REQUEST, getLessonMembershipRequest);
}

function* getPaymentLogRequest({ payload }) {
    const resp = yield call(getPaymentLogApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getPaymentLogSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getPaymentLogFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getPaymentLogFailureAction({ error }));
    }
}

function* getPracticeMembershipRequest({ payload }) {
    const resp = yield call(getPracticeMembershipApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getPracticeMembershipSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getPracticeMembershipFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getPracticeMembershipFailureAction({ error }));
    }
}

function* getLessonMembershipRequest({ payload }) {
    const resp = yield call(getLessonMembershipApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getLessonMembershipSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getLessonMembershipFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getLessonMembershipFailureAction({ error }));
    }
}
