import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import { SEVEN_STAGE_SWING_LIBRARY_URL } from "../../../Utils/routerConstants";

import BackendLayout from "../../Layout/BackendLayout";

// Lesson
// import SevenStageSwingLibraryPage from "./index";
// import SevenStageSwingLibraryDetail from "./Detail";
const SevenStageSwingLibraryPage = lazy(() => import('./index'));
const SevenStageSwingLibraryDetail = lazy(() => import('./Detail'));


const routers = [
  {
    path: `${SEVEN_STAGE_SWING_LIBRARY_URL}/:slug/:detailSlug`,
    name: "Seven Stage Swing Library Detail",
    component: SevenStageSwingLibraryDetail,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: `${SEVEN_STAGE_SWING_LIBRARY_URL}/:slug`,
    name: "Seven Stage Swing Library By Category",
    component: SevenStageSwingLibraryPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: SEVEN_STAGE_SWING_LIBRARY_URL,
    name: "Seven Stage Swing Library",
    component: SevenStageSwingLibraryPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
];

export default routers;