import * as actions from "../Constants/actionTypes";
import { AlertError } from "../../Utils/alertUtils";
import { paginations_default_size } from "../../Utils/globalUtils"

const initState = {

    // Payment Log

    isLoading: false,
    data: {
        totals: 1,
        page_size: paginations_default_size,
        current_page: 1,
        next_page: null,
        previous_page: null,
        pages: 1,
        results: []
    },

    // Practice membership
    isPracticeMembershipLoading: false,
    practiceMembershipData: {},

    // Lesson membership
    isLessonMembershipLoading: false,
    lessonMembershipData: {},
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        // get payment log
        case actions.AT_GET_PAYMENT_LOG_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actions.AT_GET_PAYMENT_LOG_SUCCESS: {
            const { totals, page_size } = action.payload;
            return {
                ...state,
                isLoading: false,
                data: {
                    ...action.payload,
                    pages: Math.ceil(totals / page_size)
                }
            };
        }
        case actions.AT_GET_PAYMENT_LOG_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false,
                data: initState.data
            };
        }


        // get Practice membership
        case actions.AT_GET_PRACTICE_MEMBERSHIP_REQUEST: {
            return {
                ...state,
                isPracticeMembershipLoading: true,
            };
        }
        case actions.AT_GET_PRACTICE_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isPracticeMembershipLoading: false,
                practiceMembershipData: action.payload
            };
        }
        case actions.AT_GET_PRACTICE_MEMBERSHIP_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isPracticeMembershipLoading: false,
                practiceMembershipData: initState.practiceMembershipData
            };
        }
        // get Lesson membership
        case actions.AT_GET_LESSON_MEMBERSHIP_REQUEST: {
            return {
                ...state,
                isLessonMembershipLoading: true,
            };
        }
        case actions.AT_GET_LESSON_MEMBERSHIP_SUCCESS: {
            return {
                ...state,
                isLessonMembershipLoading: false,
                lessonMembershipData: action.payload
            };
        }
        case actions.AT_GET_LESSON_MEMBERSHIP_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLessonMembershipLoading: false,
                lessonMembershipData: initState.lessonMembershipData
            };
        }
        default:
            return state;
    }
};

export default myReducer;
