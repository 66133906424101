import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";
import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getUserProfileSuccessAction,
    getUserProfileFailureAction,
    updateProfileFailureAction,
    updateProfileSuccessAction,
    changePasswordFailureAction,
    changePasswordSuccessAction,

    getUserDashboardFailureAction,
    getUserDashboardSuccessAction,
    countMessageUnreadSuccessAction,
    countMessageUnreadFailureAction
} from "../Actions/userAction";
import { updateProfileApi, getUserProfileApi, changePasswordApi, getUserDashboardApi } from "../../Apis/userApi";
import { getUserEmailFromAccessToken } from './../../Utils/localStorageUtils'
import { getUserProfileRequestAction } from "./../Actions/userAction";
import { countUnreadMessageStudentApi } from "Apis/chatApi";

export default function* userSaga() {
    yield takeLatest(actions.AT_CHANGE_PASSWORD_REQUEST, changePasswordRequestSaga);
    yield takeLatest(actions.AT_UPDATE_PROFILE_REQUEST, updateProfileRequestSaga);
    yield takeLatest(actions.AT_USER_GET_PROFILE_REQUEST, getUserProfileRequest);
    yield takeLatest(actions.AT_GET_USER_DASHBOARD_REQUEST, getUserDashboardRequest);
    yield takeLatest(actions.AT_COUNT_MESSAGE_UNREAD_REQUEST, countMessageUnreadRequest);
}


function* getUserDashboardRequest() {
    const resp = yield call(getUserDashboardApi);
    // var { status, data } = resp;
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getUserDashboardSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getUserDashboardFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getUserDashboardFailureAction({ error }));
    }
}

function* changePasswordRequestSaga({ payload }) {
    const { new_password, old_password, email } = payload.data;
    // var email = getUserEmailFromAccessToken()
    const resp = yield call(changePasswordApi, {
        email,
        password: new_password,
        old_password,
    });
    const { status } = resp;
    if (status === STATUS_CODE.HTTP_200_OK) {
        const message = "Change password successfully!";
        yield put(changePasswordSuccessAction({message}));
    }else if (status === STATUS_CODE.HTTP_ME_454_OLD_PASSWORD_IS_INCORRECT) {
        const error = "Old password is incorrect!";
        yield put(changePasswordFailureAction({ error }));
    }else {
        const error = "Can not change password at this time. Please try again!";
        yield put(changePasswordFailureAction({ error }));
    }
}

function* updateProfileRequestSaga({ payload }) {
    const { first_name, last_name, profile, email } = payload.data;
    // var email = getUserEmailFromAccessToken()
    const resp = yield call(updateProfileApi, payload.data);
    const { status, data } = resp;
    if (status === STATUS_CODE.HTTP_200_OK) {
        const { message } = data;
        yield put(updateProfileSuccessAction(message));
        yield put(getUserProfileRequestAction());
    } else if (status === STATUS_CODE.HTTP_ME_450_EMAIL_EXIST) { 
        const error = "Email exist!";
        yield put(updateProfileFailureAction({ error }));
    } else {
        const error = "Can not update profile at this time. Please try again!";
        yield put(updateProfileFailureAction({ error }));
    }
}

function* getUserProfileRequest() {
    const resp = yield call(getUserProfileApi);
    var { status, data } = resp;
    if (status === STATUS_CODE.HTTP_200_OK) {
        yield put(getUserProfileSuccessAction(data));
    } else {
        const error = "Can not get profile data at this time. Please try again!";
        yield put(getUserProfileFailureAction({ error }));
    }
}


function* countMessageUnreadRequest() {
    const resp = yield call(countUnreadMessageStudentApi);
    // var { status, data } = resp;
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(countMessageUnreadSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(countMessageUnreadFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(countMessageUnreadFailureAction({ error }));
    }
}