import React, { useEffect } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
// import { SnackbarContainer } from "uno-material-ui";
import { ToastContainer } from 'react-toastify';
import { connect } from "react-redux";
import { compose } from "redux";
import 'react-toastify/dist/ReactToastify.css';
//
import history from "../Utils/historyUtils";

import frontendRouters from "../Routes/FrontendRouteList";
import FrontendRoute from "../Routes/FrontendRoute";
//
import backendRouters from "../Routes/BackendRouteList";
import AdminRoute from "../Routes/AdminRoute";

import FrontendLayout from "../Frontend/Layout/FrontendLayout";
import NotFoundPage from "../Frontend/Pages/NotFoundPage/NotFoundPage";

import { getUserProfileRequestAction } from "../Store/Actions/userAction";

const Main = props => {
    useEffect(() => {
        if(!!props.isAuthenticated){
            props.getUserProfile()
        }
    }, [props.isAuthenticated])

    return (
        <BrowserRouter history={history}>
            <Switch>
                {/* Frontend Route============================================== */}
                {
                    frontendRouters.map((v, k) => (
                        <FrontendRoute key={k} {...v} />
                    ))
                }
                {/* Backend Route============================================== */}
                {
                    backendRouters.map((v, k) => (
                        <AdminRoute key={k} {...v} />
                    ))
                }
                {/* Not Found Page============================================== */}
                <FrontendRoute component={NotFoundPage} layout={FrontendLayout} />
            </Switch>
            <ToastContainer />
            {/* <SnackbarContainer /> */}
        </BrowserRouter>
    );
}

const mapStateToProps = (state) => ({
	isAuthenticated: state.authentication.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
	getUserProfile: (data) => {
		dispatch(getUserProfileRequestAction());
	},
});

const withCompose = compose(
	connect(mapStateToProps, mapDispatchToProps)
);

export default withCompose(Main);
