const initState = {
    sidebarShow: 'responsive',
    sidebarMini: false,
    asideShow: false,
    darkMode: false
};

const themeReducer = (state = initState, {type, ...rest}) => {
    switch (type) {
        case 'set':
            return {
                ...state, 
                ...rest
            }
        default:
            return state;
    }
};

export default themeReducer;
