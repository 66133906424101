import moment from "moment"
import { get, chain, find } from "lodash"

export const _range = (from, to, step) => [...Array(Math.floor((to - from) / step) + 1)].map((_, i) => from + i * step);

export const paginations_default_size = 10;
export const paginations_25_items = 25;

export const convertToPrice = (price = 0) => {
  try {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',

      // These options are needed to round to whole numbers if that's what you want.
      //minimumFractionDigits: 0,
      //maximumFractionDigits: 0,
    });
    return formatter.format(price);
  } catch (error) {
    return ''
  }
}

export const dateTimeDefaultFormat = (value, format = "hh:mm A DD/MM/YYYY") => moment(value).format(format);

export const dateFormatText = "DD/MM/YYYY";
export const dateTimeFormatText = "hh:mm A DD/MM/YYYY";
export const dateFormatInput = "YYYY-MM-DD";
export const dateTimeFormatInput = "YYYY-MM-DD HH:mm:ss";

export const getQueryParams = locationSearch => locationSearch.replace('?', '').split('&').reduce((r, e) => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {})

export const setQueryParams = function(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}

export const getPreviewImgFromFileInput = (file, setSrc) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = function (e) {
    setSrc(reader.result);
  }.bind(this);
}

export const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, '');

  // Make the string lowercase
  str = str.toLowerCase();

  // Remove accents, swap ñ for n, etc
  var from = "ÁÄÂÀÃÅČÇĆĎÉĚËÈÊẼĔȆÍÌÎÏŇÑÓÖÒÔÕØŘŔŠŤÚŮÜÙÛÝŸŽáäâàãåčçćďéěëèêẽĕȇíìîïňñóöòôõøðřŕšťúůüùûýÿžþÞĐđßÆa·/_,:;";
  var to = "AAAAAACCCDEEEEEEEEIIIINNOOOOOORRSTUUUUUYYZaaaaaacccdeeeeeeeeiiiinnooooooorrstuuuuuyyzbBDdBAa------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  // Remove invalid chars
  str = str.replace(/[^a-z0-9 -]/g, '')
    // Collapse whitespace and replace by -
    .replace(/\s+/g, '-')
    // Collapse dashes
    .replace(/-+/g, '-');

  return str;
}

export const delay = (fn, ms) => {
  let timer = 0
  return function(...args) {
    args && args.length > 0 && args[0].persist();
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

export function getVideoCover(file, seekTo = 0.0) {
  console.log("getting video cover for file: ", file);
  return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement('video');
      videoPlayer.setAttribute('src', URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener('error', (ex) => {
          reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener('loadedmetadata', () => {
          // seek to user defined timestamp (in seconds) if possible
          if (videoPlayer.duration < seekTo) {
              reject("video is too short.");
              return;
          }
          // delay seeking or else 'seeked' event won't fire on Safari
          setTimeout(() => {
            videoPlayer.currentTime = seekTo;
          }, 200);
          // extract video thumbnail once seeking is complete
          videoPlayer.addEventListener('seeked', () => {
              // // console.log('video is now paused at %ss.', seekTo);
              // define a canvas to have the same dimension as the video
              const canvas = document.createElement("canvas");
              canvas.width = videoPlayer.videoWidth;
              canvas.height = videoPlayer.videoHeight;
              // draw the video frame to canvas
              const ctx = canvas.getContext("2d");
              ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
              // return the canvas image as a blob
              ctx.canvas.toBlob(
                  blob => {
                      // resolve(blob);
                      const _image = new File([blob], `${file.name}.jpg`, { type: "image/jpeg" });
                      resolve(_image);
                  },
                  "image/jpeg",
                  0.75 /* quality */
              );
          });
      });
  });
}

export function replaceUrlParam(paramName, paramValue){
  var url = window.location.href;

  if (paramValue == null) {
      paramValue = '';
  }

  var pattern = new RegExp('\\b('+paramName+'=).*?(&|#|$)');
  if (url.search(pattern)>=0) {
      return url.replace(pattern,'$1' + paramValue + '$2');
  }

  url = url.replace(/[?#]$/,'');
  return url + (url.indexOf('?')>0 ? '&' : '?') + paramName + '=' + paramValue;
}

export const filterValidToken = token => (!token.is_free && ((!!token.add_only_first_time && token.available > 0) || !token.add_only_first_time))

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const groupByFocusZoneLibrary = (arr = [], group_by = 'video_library') => {
  const focus_zone_videos = arr.map(v => v.focus_zone_video_obj);
	return chain(focus_zone_videos || []).groupBy(group_by).value();
}

export const getGroupByFocusZone = (arr = []) => {
	// return chain(arr || []).groupBy('focus_zone_video_obj.sub_category').map(v => ({
  //   ...get(v, '[0]focus_zone_video_obj.sub_category_obj'),
  //   items: chain(v || []).groupBy('focus_zone_video_obj.video_library').map(v1 => ({
  //     ...get(v1, '[0]focus_zone_video_obj.video_library_obj'),
  //     items: v1
  //   })).value(),
  // })).value();
  return chain(arr || []).groupBy('focus_zone_video_obj.video_library').map(v => ({
    ...get(v, '[0]focus_zone_video_obj.video_library_obj'),
    items: v,
  })).value();
}

export const getGroupByFocusZoneLibrary = (arr = []) => {
	return chain(arr || []).orderBy('focus_zone_video_obj.video_library').value();
}