import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import { EXERCISE_LIBRARY_URL } from "../../../Utils/routerConstants";

import BackendLayout from "../../Layout/BackendLayout";

// Lesson
// import ExerciseLibraryPage from "./index";
// import ExerciseLibraryDetail from "./Detail";
const ExerciseLibraryPage = lazy(() => import('./index'));
const ExerciseLibraryDetail = lazy(() => import('./Detail'));
const ExerciseLibraryForm = lazy(() => import('./form'));


const routers = [
  {
    path: `${EXERCISE_LIBRARY_URL}/edit/:slug`,
    name: "Edit Exercise Library",
    component: ExerciseLibraryForm,
    layout: BackendLayout,
    allows: [ROLES.COACH, ROLES.MEMBER],
  },
  {
    path: `${EXERCISE_LIBRARY_URL}/:slug/:detailSlug`,
    name: "Exercise Library Detail",
    component: ExerciseLibraryDetail,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: `${EXERCISE_LIBRARY_URL}/add`,
    name: "Add New Exercise Library",
    component: ExerciseLibraryForm,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: `${EXERCISE_LIBRARY_URL}/:slug`,
    name: "Exercise Library By Category",
    component: ExerciseLibraryPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: EXERCISE_LIBRARY_URL,
    name: "Exercise Library",
    component: ExerciseLibraryPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
];

export default routers;