import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import * as URLS from "../../../Utils/routerConstants";

import BackendLayout from "../../Layout/BackendLayout";

const ChatPage = lazy(() => import('./index'));

const routers = [
  {
    path: `${URLS.CHAT}/:uuid`,
    name: "Chat",
    component: ChatPage,
    layout: BackendLayout,
    allows: [ROLES.COACH, ROLES.MEMBER],
  },
];

export default routers;