import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// library image 
export const getImageLibraryApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_IMAGE_LIBRARY}?${data}`);
};

export const getImageLibraryDetailApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_IMAGE_LIBRARY}${data}/`);
};

export const createImageLibraryApi = (data, config = {}) => {
    return axiosService.postWithToken(urlApis.API_GET_IMAGE_LIBRARY, data, config);
};

export const updateImageLibraryApi = (id, data, config = {}) => {
    return axiosService.putWithToken(`${urlApis.API_GET_IMAGE_LIBRARY}${id}/`, data, config);
};

export const deleteImageLibraryApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_GET_IMAGE_LIBRARY}${id}/`);
};


// library video
export const getAllVideoApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_VIDEO}get-all-video/?${data}`);
};

// get all library group by category
export const getAllLibraryGroupbyCategoryApi = (data='') => {
    return axiosService.getWithToken(`${urlApis.API_GET_ALL_LIBRARY_BY_CATEGORY}?${data}`);
};

export const createVideoLibraryApi = (data, config = {}) => {
    return axiosService.postWithToken(urlApis.API_VIDEO, data, config);
};

export const updateVideoLibraryApi = (id, data, config = {}) => {
    return axiosService.putWithToken(`${urlApis.API_VIDEO_DETAILS}${id}/`, data, config);
};

export const deleteVideoLibraryApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_VIDEO_DETAILS}${id}/`);
};

export const getVideoLibraryDetailApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_VIDEO_DETAILS}${id}/`);
};