import React from "react";
import {
	CSpinner
} from "@coreui/react";

const Loading = () => {
  return (
    <div className="text-center">
      <CSpinner color="primary" />
    </div>
  )
}

export default Loading;