import * as actions from "../Constants/actionTypes";

// get lesson list
export const getGolfSwingFailureAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_FAILURE,
    payload,
});

export const getGolfSwingSuccessAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_SUCCESS,
    payload,
});

export const getGolfSwingRequestAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_REQUEST,
    payload,
});


// get token by user id
export const getGolfSwingTokenByUserFailureAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_TOKEN_BY_USER_FAILURE,
    payload,
});

export const getGolfSwingTokenByUserSuccessAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_TOKEN_BY_USER_SUCCESS,
    payload,
});

export const getGolfSwingTokenByUserRequestAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_TOKEN_BY_USER_REQUEST,
    payload,
});



// get all golf swings
export const getGolfSwingGetAllFailureAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_GET_ALL_FAILURE,
    payload,
});

export const getGolfSwingGetAllSuccessAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_GET_ALL_SUCCESS,
    payload,
});

export const getGolfSwingGetAllRequestAction = (payload) => ({
    type: actions.AT_GET_GOLF_SWING_GET_ALL_REQUEST,
    payload,
});
