import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getFocusZoneListFailureAction,
    getFocusZoneListSuccessAction,
    
    getFocusZoneListLibraryFailureAction,
    getFocusZoneListLibrarySuccessAction,
    
    getFocusZoneVideoListLibraryFailureAction,
    getFocusZoneVideoListLibrarySuccessAction,
} from "../Actions/globalAction";
import { 
    getFocusZoneListApi,
    getFocusZoneLibraryListApi,
    getFocusZoneVideoLibraryListApi,
} from "../../Apis/globalApi";



export default function* mySaga() {
    yield takeLatest(actions.AT_GLOBAL_REQUEST, getFocusZoneListRequest);
}

function* getFocusZoneListRequest({ payload, action_type }) {
    // let resp;
    if(action_type === actions.AT_GET_FOCUS_ZONES_LIST) {
        const resp = yield call(getFocusZoneListApi, payload);
        if (resp) {
            const { status, data } = resp;
            if (status === STATUS_CODE.HTTP_200_OK) {
                yield put(getFocusZoneListSuccessAction(data));
            } else {
                const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
                yield put(getFocusZoneListFailureAction({ error }));
            }
        } else {
            const error = "Can not get data at this time. Please try again!";
            yield put(getFocusZoneListFailureAction({ error }));
        }
    }

    if(action_type === actions.AT_GET_FOCUS_ZONES_LIST) {
        const resp = yield call(getFocusZoneLibraryListApi, payload);
        if (resp) {
            const { status, data } = resp;
            if (status === STATUS_CODE.HTTP_200_OK) {
                yield put(getFocusZoneListLibrarySuccessAction(data));
            } else {
                const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
                yield put(getFocusZoneListLibraryFailureAction({ error }));
            }
        } else {
            const error = "Can not get data at this time. Please try again!";
            yield put(getFocusZoneListLibraryFailureAction({ error }));
        }
    }

    if(action_type === actions.AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST) {
        const resp = yield call(getFocusZoneVideoLibraryListApi, payload);
        if (resp) {
            const { status, data } = resp;
            if (status === STATUS_CODE.HTTP_200_OK) {
                yield put(getFocusZoneVideoListLibrarySuccessAction(data));
            } else {
                const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
                yield put(getFocusZoneVideoListLibraryFailureAction({ error }));
            }
        } else {
            const error = "Can not get data at this time. Please try again!";
            yield put(getFocusZoneVideoListLibraryFailureAction({ error }));
        }
    }
}
