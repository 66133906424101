import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

export const getGolfSwingApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}?${data}`);
};

// export const getLessonPostApi = ({ query, url = "" }) => {
//     return axiosService.getWithToken(`${urlApis.API_LESSON}get-post/${url}?${query}`);
// };

// export const getAlLessonCategoryApi = (data) => {
//     return axiosService.getWithToken(`${urlApis.API_LESSON}categories/`);
// };

export const getLastDraftGolfSwingApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}get-last-draft/`);
};

export const getGolfSwingDetailApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}${id}/`);
};

export const createGolfSwingApi = (data, config = {}) => {
    return axiosService.postWithToken(urlApis.API_GET_GOLF_SWING_USER, data, config);
};

export const updateGolfSwingApi = (id, data, config = {}) => {
    return axiosService.putWithToken(`${urlApis.API_GET_GOLF_SWING_USER}${id}/`, data, config);
};

export const deleteGolfSwingApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_GET_GOLF_SWING_USER}${id}/`);
};

export const deleteGolfSwingVideoApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_GET_GOLF_SWING_USER}delete-swings-video/${id}/`);
};

export const submitGolfSwingApi = (id, data) => {
    return axiosService.postWithToken(`${urlApis.API_GET_GOLF_SWING_USER}submit-swings/${id}/`, data);
};

export const getPaymentItemsApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}get-payment-items/`);
};

export const createPaymentLogApi = data => {
    return axiosService.postWithToken(`${urlApis.API_GET_GOLF_SWING_USER}payment-logs/`, data);
};

export const updatePaymentLogApi = (id, data) => {
    return axiosService.putWithToken(`${urlApis.API_GET_GOLF_SWING_USER}payment-logs/${id}/`, data);
};

export const addMoreTokensApi = data => {
    return axiosService.postWithToken(`${urlApis.API_GET_GOLF_SWING_USER}tokens/add-more/`, data);
};

export const getGolfSwingTokenByUserApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}tokens/?${data}`);
};

export const updateTokenApi = (id, data, params) => {
    return axiosService.putWithToken(`${urlApis.API_GET_GOLF_SWING_USER}tokens/${id}/?${params}`, data);
};

export const getGolfSwingGetAllApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}get-all/?${data}`);
};

export const getTokenTypeGetAllApi = () => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}tokens/get-all-type/`);
};

export const getPaymentLogApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_GET_GOLF_SWING_USER}payment-logs/?${data}`);
};