import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

export const getNumberOfLessonsGivenApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__NUMBER_OF_LESSONS_GIVEN}?${query}`);
};

export const getLessonOfCoachApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__LESSON_OF_COACH}?${query}`);
};

export const getRevenueBrokenDownByProductApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__REVENUE_BROKEN_DOWN_BY_PRODUCT}?${query}`);
};

export const getUsersCancelledSubscriptionApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__USERS_CANCELLED_SUBSCRIPTION}?${query}`);
};

export const getPaymentLogsApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__PAYMENT_LOGS}?${query}`);
};

export const getCreatePageViewApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_REPORTS__CREATE_PAGEVIEW}`, data);
};

export const getPageViewApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__PAGE_VIEW}?${query}`);
};

export const getAtRiskReportApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_REPORTS__AT_RISK_REPORT}?${query}`);
};

export const getReportDashboardApi = (query, type) => {
    const url_type = type ? `${type}/` : '';
    return axiosService.getWithToken(`${urlApis.API_REPORTS__DASH_BOARD}${url_type}?${query}`);
};

export const getReportMembersApi = (query, type) => {
    const url_type = type ? `${type}/` : '';
    return axiosService.getWithToken(`${urlApis.API_REPORTS__MEMBERS}${url_type}?${query}`);
};
