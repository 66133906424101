import * as actions from "../Constants/actionTypes";
import { AlertError } from "../../Utils/alertUtils";

const initState = {
    isLoading: false,
    data: null,
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.AT_GET_STUDENTS_OF_COACH_DETAIL_REQUEST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actions.AT_GET_STUDENTS_OF_COACH_DETAIL_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                data: action.payload
            };
        }
        case actions.AT_GET_STUDENTS_OF_COACH_DETAIL_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                isLoading: false,
                data: initState.data
            };
        }
        default:
            return state;
    }
};

export default myReducer;
