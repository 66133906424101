import { element } from "prop-types";
import * as actions from "../Constants/actionTypes";
import { AlertError, AlertSuccess } from "../../Utils/alertUtils";

const initState = {
    // Video Category
    isLoading: false,
    detail: null,
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        // get payment log
        case actions.AT_GET_VIDEO_CATEGORY_DETAIL_REQUEST: {
            return {
                isLoading: true,
                detail: null,
            };
        }
        case actions.AT_GET_VIDEO_CATEGORY_DETAIL_SUCCESS: {
            return {
                isLoading: false,
                detail: action.payload
            };
        }
        case actions.AT_GET_VIDEO_CATEGORY_DETAIL_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                isLoading: false,
                detail: null,
            };
        }
        default:
            return state;
    }
};

export default myReducer;
