import React from 'react'
import { get, findIndex } from 'lodash';
import { withRouter, Link } from 'react-router-dom';
import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from '@coreui/react'
import { connect } from "react-redux";
import { compose } from "redux";
import logoImg from "../../../Assets/images/golftec_logo.png"
import logoIcon from "../../../Assets/images/golftec-icon.png"
// sidebar nav config
import navigation from './_nav'
import './TheSidebar.scss';

import { isCoach } from '../../../Utils/roleAndPermUtils'
import { ROLES } from "../../../Utils/roleAndPermUtils";
import { VIDEO_COURSE_CATEGORIES_URL, PAYMENT_HISTORY_FOR_COACH_URL ,MAIN_WEBSITE_URL, PAYMENT_HISTORY_URL, DASHBOARD_URL } from "../../../Utils/routerConstants";

const CSidebarNavItemCus = props => {
  return (
    <li className="c-sidebar-nav-item c-sidebar-nav-item-cus">
      <Link className="c-sidebar-nav-link" to={props.to} title={props.name}>
        <div>
          {/* <img src={props.img} height="17px" className="mr-2"/> */}
          {props.name}
        </div>
        {/* <div className="description">{props.description}</div> */}
      </Link>
    </li>
  )
}

const hostNameLink = DASHBOARD_URL;

const TheSidebar_VideoCourses = (props) => {
  const show = props.theme.sidebarShow;
  const groups = props.profile.groups;

  return (
    <CSidebar
      show={show}
      unfoldable
      onShowChange={(val) => props.setTheme({ type: 'set', sidebarShow: val })}
      className="custom-sidebar"
    >
      <CSidebarBrand className="d-md-down-none" href={MAIN_WEBSITE_URL} target="_blank">
        <img src={logoImg} className="c-sidebar-brand-full" height={35} alt="" />
        <img src={logoIcon} className="c-sidebar-brand-minimized" height={35} alt="" />
        {/* <CIcon
          className="c-sidebar-brand-full"
          name="logo-negative"
          height={35}
        />
        <CIcon
          className="c-sidebar-brand-minimized"
          name="sygnet"
          height={35}
        /> */}
      </CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem name={"All video courses"} to={VIDEO_COURSE_CATEGORIES_URL} icon={'cil-video'} />
        <CSidebarNavItem name={"Payment History"} to={isCoach(groups)?PAYMENT_HISTORY_FOR_COACH_URL:PAYMENT_HISTORY_URL} icon={'cil-history'} />
        <CSidebarNavItem name={"Back"} to={hostNameLink} icon={'cil-arrow-circle-left'} />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

const mapStateToProps = (state) => ({
  // state: state,
  isAuthenticated: state.authentication.isAuthenticated,
  // 
  profile: state.user.profile,
  categories: state.categoriesReducer,
});

const mapDispatchToProps = (dispatch) => ({
});

const withCompose = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default React.memo(withRouter(withCompose(TheSidebar_VideoCourses)))
