import * as actions from "./../Constants/actionTypes";

// reset password

export const resetPasswordFailureAction = (payload) => ({
    type: actions.AT_AUTH_RESET_PASSWORD_FAILURE,
    payload,
});

export const resetPasswordSuccessAction = (payload) => ({
    type: actions.AT_AUTH_RESET_PASSWORD_SUCCESS,
    payload,
});

export const resetPasswordRequestAction = (data) => ({
    type: actions.AT_AUTH_RESET_PASSWORD_REQUEST,
    payload: { data },
});

// active account

export const activeAccountFailureAction = (payload) => ({
    type: actions.AT_AUTH_ACTIVE_ACCOUNT_FAILURE,
    payload,
});

export const activeAccountSuccessAction = (payload) => ({
    type: actions.AT_AUTH_ACTIVE_ACCOUNT_SUCCESS,
    payload,
});

export const activeAccountRequestAction = (data) => ({
    type: actions.AT_AUTH_ACTIVE_ACCOUNT_REQUEST,
    payload: { data },
});

// resend activation link

export const resendActivationLinkFailureAction = (payload) => ({
    type: actions.AT_AUTH_RESEND_LINK_ACTIVATION_FAILURE,
    payload,
});

export const resendActivationLinkSuccessAction = (payload) => ({
    type: actions.AT_AUTH_RESEND_LINK_ACTIVATION_SUCCESS,
    payload,
});

export const resendActivationLinkRequestAction = (data) => ({
    type: actions.AT_AUTH_RESEND_LINK_ACTIVATION_REQUEST,
    payload: { data },
});

// forgot password

export const forgotPasswordFailureAction = (payload) => ({
    type: actions.AT_AUTH_FORGOT_PASSWORD_FAILURE,
    payload,
});

export const forgotPasswordSuccessAction = (payload) => ({
    type: actions.AT_AUTH_FORGOT_PASSWORD_SUCCESS,
    payload,
});

export const forgotPasswordRequestAction = (data) => ({
    type: actions.AT_AUTH_FORGOT_PASSWORD_REQUEST,
    payload: { data },
});

// signup

export const signupFailureAction = (payload) => ({
    type: actions.AT_AUTH_SIGNUP_FAILURE,
    payload,
});

export const signupSuccessAction = (payload) => ({
    type: actions.AT_AUTH_SIGNUP_SUCCESS,
    payload,
});

export const signupRequestAction = (data, callback) => ({
    type: actions.AT_AUTH_SIGNUP_REQUEST,
    payload: { data, callback },
});

// login

export const loginFailureAction = (payload) => ({
    type: actions.AT_AUTH_LOGIN_FAILURE,
    payload,
});

export const loginSuccessAction = (payload) => ({
    type: actions.AT_AUTH_LOGIN_SUCCESS,
    payload,
});

export const loginRequestAction = (data, callback) => ({
    type: actions.AT_AUTH_LOGIN_REQUEST,
    payload: { data, callback },
});

// logout

export const logoutAction = () => ({
    type: actions.AT_AUTH_LOGOUT,
});
