import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import * as URLS from "../../../Utils/routerConstants";

import BackendLayout from "../../../Backend/Layout/BackendLayout";
import BackendLayoutNoContent from "../../../Backend/Layout/BackendLayoutNoContent";

// Images Library
// import ImagesLibraryPage from "./index";
// import ImagesLibraryFormPage from "./form";
// import LibraryList from "./LibraryList";
const ImagesLibraryPage = lazy(() => import('./index'));
const ImagesLibraryFormPage = lazy(() => import('./form'));
const LibraryList = lazy(() => import('./LibraryList'));


const routers = [
  // Library for coach only
  {
    path: "/ckeditor/images-library",
    name: "Ckeditor Library",
    component: LibraryList,
    layout: BackendLayoutNoContent,
    allows: [ROLES.COACH],
  },
  {
    path: `${URLS.IMAGES_LIBRARY_URL}/add`,
    name: "Add New Image",
    component: ImagesLibraryFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: `${URLS.IMAGES_LIBRARY_URL}/:id`,
    name: "Edit Image",
    component: ImagesLibraryFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.IMAGES_LIBRARY_URL,
    name: "Images",
    component: ImagesLibraryPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  }
];

export default routers;