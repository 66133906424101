/** =============== GOLF SWING STATUS =========== */
export const GOLF_SWING_STATUS__DRAFT_TYPE = "draft";
export const GOLF_SWING_STATUS__SUBMITTED_TYPE = "submitted";
export const GOLF_SWING_STATUS__EVALUATED_TYPE = "evaluated";
export const GOLF_SWING_STATUS = {
  [GOLF_SWING_STATUS__DRAFT_TYPE]: "Draft",
  [GOLF_SWING_STATUS__SUBMITTED_TYPE]: "Pending",
  [GOLF_SWING_STATUS__EVALUATED_TYPE]: "Analysed",
};

export const GOLF_SWING_VIDEO_TYPE__FACE_ON_TYPE = "face_on";
export const GOLF_SWING_VIDEO_TYPE__DOWN_THE_LINE_TYPE = "down_the_line";

/** =============== GOLF SWING PAYMENT ITEMS TYPE =========== */
export const GOLF_SWING_PAYMENT_ITEMS__MEMBERSHIP_TYPE = 1;
export const GOLF_SWING_PAYMENT_ITEMS__ON_DEMAND_TYPE = 2;
export const GOLF_SWING_PAYMENT_ITEMS__NONE = 0;
export const GOLF_SWING_PAYMENT_ITEMS = {
  [GOLF_SWING_PAYMENT_ITEMS__MEMBERSHIP_TYPE]: "Membership",
  [GOLF_SWING_PAYMENT_ITEMS__ON_DEMAND_TYPE]: "On Demand",
  [GOLF_SWING_PAYMENT_ITEMS__NONE]: "-",
};

export const GOLF_SWING_TOKEN_TYPES__ONLINE_LESSON = "Online Lesson";
export const GOLF_SWING_TOKEN_TYPES__FACE_TIME_LESSON = "Facetime Lesson";
export const GOLF_SWING_TOKEN_TYPES__FREE_LESSON = "Free Lesson";
export const GOLF_SWING_TOKEN_TYPES__COLORS = {
  [GOLF_SWING_TOKEN_TYPES__ONLINE_LESSON]: "#0caad4",
  [GOLF_SWING_TOKEN_TYPES__FACE_TIME_LESSON]: "#04DE92",
  [GOLF_SWING_TOKEN_TYPES__FREE_LESSON]: "#999",
};


export const PAYPAL__REPONSE_STATUS = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
}

/** =============== STRIPE PAYMENT =========== */
export const STRIPE_ADD_CARD_TYPE__SUCCESS = "success";
export const STRIPE_ADD_CARD_TYPE__CANCEL = "cancel";

export const STRIPE_PAYMENT_OBJECT_TYPE = {
  PAYMENT_INTENT: "payment_intent",
  SUBSCRIPTION: "subscription",
};

// https://stripe.com/docs/api/subscriptions/object#subscription_object-status
// https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
export const STRIPE_PAYMENT_RESPONSE_STATUS = {
  canceled: "canceled",

  // subscription_object-status https://stripe.com/docs/api/subscriptions/object#subscription_object-status
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  trialing: "trialing",
  active: "active",
  past_due: "past_due",
  unpaid: "unpaid",

  // payment_intent_object-status https://stripe.com/docs/api/payment_intents/object#payment_intent_object-status
  requires_payment_method: "requires_payment_method",
  requires_confirmation: "requires_confirmation",
  requires_action: "requires_action",
  processing: "processing",
  requires_capture: "requires_capture",
  succeeded: "succeeded",
};

export const STRIPE_PAYMENT_SUCCESS_STATUS = [
  STRIPE_PAYMENT_RESPONSE_STATUS.active,
  STRIPE_PAYMENT_RESPONSE_STATUS.trialing,
  STRIPE_PAYMENT_RESPONSE_STATUS.succeeded,
];

export const DELAY_FILTER_TIME = 500;

export const CHART_OPTIONS = {
  tooltips: {
    enabled: true,
  },
  scales: {
    xAxes: [
      // {
      //   gridLines: {
      //     display: false,
      //   },
      // },
    ],
    yAxes: [
      {
        gridLines: {
          display: false,
        },
        ticks: {
          beginAtZero: true,
          // stepSize: 1,
          callback: (value) => value % 1 === 0 && value,
        },
      },
    ],
  },
};
