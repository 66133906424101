import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getGolfSwingSuccessAction,
    getGolfSwingFailureAction,

    getGolfSwingTokenByUserSuccessAction,
    getGolfSwingTokenByUserFailureAction,

    getGolfSwingGetAllFailureAction,
    getGolfSwingGetAllSuccessAction
} from "../Actions/golfSwingAction";
import { 
    getGolfSwingApi,
    getGolfSwingTokenByUserApi,
    getGolfSwingGetAllApi
} from "../../Apis/golfSwingApi";



export default function* mySaga() {
    yield takeLatest(actions.AT_GET_GOLF_SWING_REQUEST, getGolfSwingRequest);
    yield takeLatest(actions.AT_GET_GOLF_SWING_TOKEN_BY_USER_REQUEST, getGolfSwingTokenByUserRequest);
    yield takeLatest(actions.AT_GET_GOLF_SWING_GET_ALL_REQUEST, getGolfSwingGetAllRequest);
}

function* getGolfSwingRequest({ payload }) {
    const resp = yield call(getGolfSwingApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getGolfSwingSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getGolfSwingFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getGolfSwingFailureAction({ error }));
    }
}

function* getGolfSwingTokenByUserRequest({ payload }) {
    const resp = yield call(getGolfSwingTokenByUserApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getGolfSwingTokenByUserSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getGolfSwingTokenByUserFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getGolfSwingTokenByUserFailureAction({ error }));
    }
}

function* getGolfSwingGetAllRequest({ payload }) {
    const resp = yield call(getGolfSwingGetAllApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getGolfSwingGetAllSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getGolfSwingGetAllFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getGolfSwingGetAllFailureAction({ error }));
    }
}
