import { get, includes, filter } from "lodash";
import moment from "moment";

export const ROLES = {
    ADMIN: 'admin_group',
    COACH: 'coach_group',
    MEMBER: 'member_group'
}

export const GROUPS_NAMES = {
    [ROLES.ADMIN]: 'Admin',
    [ROLES.COACH]: 'Coach',
    [ROLES.MEMBER]: 'Member',
}

/** =============== USER TYPE =========== */
export const USER_TYPE__ONLINE_CLIENT = 1
export const USER_TYPE__VENUE_CLIENT = 2
export const USER_TYPES = {
    [USER_TYPE__ONLINE_CLIENT]: 'Online',
    [USER_TYPE__VENUE_CLIENT]: 'Venue'
}

/** =============== USER ACTIVE =========== */
export const USER_ACTIVE__ENABLE = 1
export const USER_ACTIVE__DISABLE = 0

export const PERMISSION_ACTION = {
    ADD: 'add',
    CHANGE: 'change',
    DELETE: 'delete',
    VIEW: 'view'
}

export const PERMISSION_MODEL = {
    PROFILE: 'profile',
    COUNTRY: 'country',
    // ...
}

export const PROFILE_MEMBERSHIP_TYPE = {
    LESSON: "payment_log_lesson",
    PRACTICE: "payment_log_practice"
}

export const getRoleName = groups => {
    if(includes(groups, ROLES.COACH)) {
        return 'Coach'
    } else if (includes(groups, ROLES.MEMBER)) {
        return 'Member'
    } else if (includes(groups, ROLES.ADMIN)) {
        return 'Admin'
    }
    return ''
}

export const getUserStatus = (user, isClassName = false) => {
    const invited = get(user, 'profile.invited', false)
    const is_active = get(user, 'is_active', false)
    if (!!invited) {
        return !isClassName ? 'Invite' : 'text-warning'
    }
    if (!!is_active) {
        return !isClassName ? 'Active' : 'text-success'
    }
    return !isClassName ? 'Blocked' : 'text-danger'
}

export const hasPermissionWithRouter = (allows = ['*'], profile) => {
    /* 
        ===============================================
        'allows' is array fix when create route. EX: allows: [ROLES.MEMBER, ROLES.COACH],
        'profile' is object have a groups array get when user login.
        ===============================================
        - Allow if have '*'
        - Allow if profile groups array has item on allows array
    */
    let groups = get(profile, 'groups', []);
    return includes(allows, '*') || (groups && groups.length > 0 && filter(groups, v => (includes(allows, v))).length > 0);
};

export const canPermission = (action = '', model = '', profile) => {
    /* 
        Only allow if have permission in profile object when user login
        EX:
            profile: {
                permissions: [
                    'Can add profile',
                    'Can change profile',
                    'Can delete profile',
                    'Can view profile'
                ],
            }
        'action' is string define on PERMISSION_ACTION array
        'model' is string define on PERMISSION_MODEL array
    */
    let permissions = get(profile, 'permissions', []);
    return action && model && permissions && permissions.length > 0 && includes(permissions, `Can ${action} ${model}`);
};

export const checkMembership = (type, profile) => {
    return true; // remove check membership
};

export const checkMembership_OLD = (type, profile) => {
    /* 
        Check current profile
        'type' is string define on PROFILE_MEMBERSHIP_TYPE array
    */
   
    let free_expiration_date = get(profile, 'free_expiration_date', false)
    if (!!free_expiration_date) { // check free expiration date
        let now = moment();
        var expiration_date = moment(free_expiration_date);
        let get_diff = expiration_date.diff(now)
        return get_diff > 0;
    }
    // return !!free_expiration_date;

    let profile_payment_log = get(profile, type);
    if (profile_payment_log) {
    // if (profile_payment_log && profile_payment_log.ended_at) {
        const now = moment();
        const profile_payment_log_ended_at = moment(profile_payment_log.ended_at);
        if(profile_payment_log.started_at && !profile_payment_log.ended_at) {
            return true;
        }
        return now < profile_payment_log_ended_at;
    }
    return false;
};

export const isMember = groups => {
    /* 
        Check user is member
    */
    return !!includes(groups, ROLES.MEMBER)
};

export const isCoach = groups => {
    /* 
        Check user is coach
    */
    return !!includes(groups, ROLES.COACH)
};

export const isAdmin = groups => {
    /* 
        Check user is admin
    */
    return !!includes(groups, ROLES.ADMIN)
};

export const isMemberOnlineClient = (user_type = USER_TYPE__ONLINE_CLIENT) => {
    /* 
        Check user is online client
    */
    return Number(user_type) == USER_TYPE__ONLINE_CLIENT
};

export const isMemberVenueClient = (user_type = USER_TYPE__VENUE_CLIENT) => {
    /* 
        Check user is Venue client
    */
    return Number(user_type) == USER_TYPE__VENUE_CLIENT
};







//============================================================================

export const hasBelongGroup = (profile) => {
    var belong_group = true;
    let groups_from_profile = profile.groups;
    let groups_from_perm = profile.groups_with_permissions;
    for (var key in groups_from_profile) {
        for (var key_1 in groups_from_perm) {
            if (key === key_1) {
                belong_group = true;
            }
        }
    }
    return belong_group;
};

export const hasBelongPerm = (profile) => {
    var belong_perm = true;
    // let perm_from_profile = profile.permissions; 
    // let groups_from_perm = profile.groups_with_permissions; 
    return belong_perm;
};