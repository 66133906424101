import React from 'react'
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CImg
} from '@coreui/react'
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from "redux";
import { get, includes } from "lodash";
import CIcon from '@coreui/icons-react'
import AvatarDefault from "./../../../Assets/avatar_default.png";

import { ROLES, getRoleName } from "../../../Utils/roleAndPermUtils";
import * as URLS from "../../../Utils/routerConstants";

const TheHeaderDropdown = (props) => {
  const handleLogout = () => {
    props.logout();
    props.setUserProfileToNull();
    props.history.push(URLS.MAIN_PAGE_URL)
  };

  // const { profile } = props;
  const groups = get(props, 'profile.groups');

  return (
    <CDropdown
      inNav
      className="c-header-nav-items mx-2"
      direction="down"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <span className="mr-2">
          Hello, {' '}
          <span className="text-capitalize">{get(props, 'profile.first_name', '')}</span>{' '}
          <small>{`(${getRoleName(groups)} account)`}</small>
        </span>
        <div className="c-avatar">
          <CImg
            src={AvatarDefault}
            className="c-avatar-img"
            alt="admin@bootstrapmaster.com"
          />
        </div>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        {/* <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Account</strong>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-bell" className="mfe-2" />
          Updates
          <CBadge color="info" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-envelope-open" className="mfe-2" />
          Messages
          <CBadge color="success" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-task" className="mfe-2" />
          Tasks
          <CBadge color="danger" className="mfs-auto">42</CBadge>
        </CDropdownItem>
        <CDropdownItem>
          <CIcon name="cil-comment-square" className="mfe-2" />
          Comments
          <CBadge color="warning" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        {/* <CDropdownItem
          header
          tag="div"
          color="light"
          className="text-center"
        >
          <strong>Settings</strong>
        </CDropdownItem> */}
        <CDropdownItem to={URLS.PROFILE_URL}>
          <CIcon name="cil-user" className="mfe-2" />Profile
        </CDropdownItem>
        <CDropdownItem to={URLS.CHANGE_PASSWORD_URL}>
          <CIcon name="cil-settings" className="mfe-2" />
          Change password
        </CDropdownItem>
        {
          includes(groups, ROLES.MEMBER) &&
          <CDropdownItem to={URLS.PAYMENT_HISTORY_URL}>
            <CIcon name="cil-history" className="mfe-2" />
            Payments History
            {/* <CBadge color="secondary" className="mfs-auto">42</CBadge> */}
          </CDropdownItem>
        }
        {/* <CDropdownItem>
          <CIcon name="cil-file" className="mfe-2" />
          Projects
          <CBadge color="primary" className="mfs-auto">42</CBadge>
        </CDropdownItem> */}
        <CDropdownItem divider />
        {/* <CDropdownItem>
          <CIcon name="cil-lock-locked" className="mfe-2" />
          Lock Account
        </CDropdownItem> */}
        <CDropdownItem onClick={() => handleLogout(props)}>
          <CIcon name="cil-door" className="mfe-2" />
          Logout
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  )
}

const mapStateToProps = (state) => ({
  // state: state,
  isAuthenticated: state.authentication.isAuthenticated,
  // 
  profile: state.user.profile,
});

const mapDispatchToProps = (dispatch) => ({
});

const withCompose = compose(
  connect(mapStateToProps, mapDispatchToProps)
);

export default withRouter(withCompose(TheHeaderDropdown));
