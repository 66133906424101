import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import * as URLS from "../../../Utils/routerConstants";

import BackendLayout from "../../../Backend/Layout/BackendLayout";

// Lesson
// import LessonManagePage from "./index";
// import LessonManageFormPage from "./form";
const LessonManagePage = lazy(() => import('./index'));
const LessonManageFormPage = lazy(() => import('./form'));
const LessonManageDetailPage = lazy(() => import('./Detail'));


const routers = [
  // Lesson for coach only
  {
    path: `${URLS.LESSON_MANAGE_ADD_URL}/:student_id`,
    name: "Add New Lesson",
    component: LessonManageFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
    layoutConfig: {
      fluid: true,
    }
  },
  {
    path: URLS.LESSON_MANAGE_ADD_URL,
    name: "Add New Lesson",
    component: LessonManageFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
    layoutConfig: {
      fluid: true,
    }
  },
  {
    path: `${URLS.LESSON_MANAGE_URL}/view/:lessonSlug`,
    name: "Detail Lesson",
    component: LessonManageDetailPage,
    layout: BackendLayout,
    allows: [ROLES.COACH, ROLES.MEMBER],
    layoutConfig: {
      fluid: true,
    }
  },
  {
    path: `${URLS.LESSON_MANAGE_URL}/:slug`,
    name: "Edit Lesson",
    component: LessonManageFormPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
    layoutConfig: {
      fluid: true,
    }
  },
  {
    path: URLS.LESSON_MANAGE_URL,
    name: "Lesson Manage",
    component: LessonManagePage,
    layout: BackendLayout,
    allows: [ROLES.MEMBER],
    layoutConfig: {
      fluid: true,
    }
  },
];

export default routers;