import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getCategoryFailureAction,
    getCategorySuccessAction,

    getCategoryDetailFailureAction,
    getCategoryDetailSuccessAction,

    getVideoDetailFailureAction,
    getVideoDetailSuccessAction,

    getVideoSearchFailureAction,
    getVideoSearchSuccessAction
} from "../Actions/videoAction";
import { 
    getVideoCategoryApi,
    getVideoCategoryDetailApi,
    getVideoDetailApi,
    getVideoSearchApi
} from "../../Apis/videoApi";

export default function* videoSaga() {
    yield takeLatest(actions.AT_GET_VIDEO_CATEGORY_REQUEST, getVideoCategoryRequest);
    yield takeLatest(actions.AT_GET_VIDEO_CATEGORY_DETAIL_REQUEST, getVideoCategoryDetailRequest);
    yield takeLatest(actions.AT_GET_VIDEO_DETAIL_REQUEST, getVideoRequest);
    yield takeLatest(actions.AT_GET_VIDEO_SEARCH_REQUEST, getVideoSearchRequest);
}

function* getVideoSearchRequest({ payload }) {
    const resp = yield call(getVideoSearchApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getVideoSearchSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getVideoSearchFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getVideoSearchFailureAction({ error }));
    }
}

function* getVideoRequest({ payload }) {
    const resp = yield call(getVideoDetailApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getVideoDetailSuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getVideoDetailFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getVideoDetailFailureAction({ error }));
    }
}

function* getVideoCategoryRequest({ payload }) {
    const resp = yield call(getVideoCategoryApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getCategorySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getCategoryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getCategoryFailureAction({ error }));
    }
}

function* getVideoCategoryDetailRequest({ payload }) {
    const resp = yield call(getVideoCategoryDetailApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getCategoryDetailSuccessAction(data));
        } else {
            console.log('data', data)
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getCategoryDetailFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getCategoryDetailFailureAction({ error }));
    }
}
