import { call, put, takeLatest } from "redux-saga/effects";
import { get } from "lodash";

import * as actions from "../Constants/actionTypes";
import { STATUS_CODE } from "../../Constants/statusCodeApi";
import {
    getImageLibrarySuccessAction,
    getImageLibraryFailureAction,

    getAllVideoLibrarySuccessAction,
    getAllVideoLibraryFailureAction
} from "../Actions/libraryAction";
import { 
    getImageLibraryApi,
    getAllVideoApi
} from "../../Apis/libraryApi";

export default function* librarySaga() {
    yield takeLatest(actions.AT_GET_IMAGE_LIBRARY_REQUEST, getImageLibraryRequest);
    yield takeLatest(actions.AT_GET_ALL_VIDEO_LIBRARY_REQUEST, getAllVideoLibraryRequest);
}

function* getAllVideoLibraryRequest({ payload }) {
    const resp = yield call(getAllVideoApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getAllVideoLibrarySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getAllVideoLibraryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getAllVideoLibraryFailureAction({ error }));
    }
}

function* getImageLibraryRequest({ payload }) {
    const resp = yield call(getImageLibraryApi, payload);
    if (resp) {
        var { status, data } = resp;
        if (status === STATUS_CODE.HTTP_200_OK) {
            yield put(getImageLibrarySuccessAction(data));
        } else {
            const error = get(data, 'detail') ? get(data, 'detail') : "Can not get data at this time. Please try again!";
            yield put(getImageLibraryFailureAction({ error }));
        }
    } else {
        const error = "Can not get data at this time. Please try again!";
        yield put(getImageLibraryFailureAction({ error }));
    }
}
