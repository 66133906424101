import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import * as URLS from "../../../Utils/routerConstants";

import BackendLayout from "../../../Backend/Layout/BackendLayout";

// Lesson
// import MyStudentsPage from "./index";
// import LessonOfStudentPage from "./LessonOfStudent";
const MyStudentsPage = lazy(() => import('./index'));
const LessonOfStudentPage = lazy(() => import('./LessonOfStudent'));
const SwingOfStudentPage = lazy(() => import('./GolfSwingOfStudent'));
// const GolfSwingGetAllPage = lazy(() => import('./GolfSwingGetAll'));


const routers = [
  // {
  //   path: "/lesson-manage/add",
  //   name: "Add New Lesson",
  //   component: LessonManageFormPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  // },
  {
    path: `${URLS.MY_STUDENT_SWINGS_URL}/:id`,
    name: "Golf Swings Of Student",
    component: SwingOfStudentPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  // {
  //   path: `${URLS.MY_STUDENT_URL}/all-swings`,
  //   name: "Golf Swings",
  //   component: GolfSwingGetAllPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  // },
  {
    path: URLS.MY_STUDENT__ALL_STUDENTS_URL,
    name: "My Students",
    component: MyStudentsPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.MY_STUDENT__ALL_SWINGS_URL,
    name: "My Students",
    component: MyStudentsPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: `${URLS.MY_STUDENT_URL}/:id`,
    name: "Lesson Of Student",
    component: LessonOfStudentPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
    layoutConfig: {
      fluid: true,
    }
  },
  {
    path: URLS.MY_STUDENT_URL,
    name: "My Students",
    component: MyStudentsPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
];

export default routers;