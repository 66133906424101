import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import * as URLS from "../../../Utils/routerConstants";

import BackendLayout from "../../../Backend/Layout/BackendLayout";

// Lesson
// import LessonManagePage from "./index";
// import LessonManageFormPage from "./form";
const HomePage = lazy(() => import('./index'));
const FormPage = lazy(() => import('./form'));
// const DetailPage = lazy(() => import('./Detail'));


const routers = [
  // {
  //   path: `${URLS.LESSON_MANAGE_ADD_URL}/:student_id`,
  //   name: "Add New Lesson",
  //   component: LessonManageFormPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH, ROLES.MEMBER],
  // },
  {
    path: URLS.GOLF_SWING_ADD_URL,
    name: "Add New Golf Swing",
    component: FormPage,
    layout: BackendLayout,
    allows: [,ROLES.COACH, ROLES.MEMBER],
  },
  // {
  //   path: `${URLS.LESSON_MANAGE_URL}/view/:lessonSlug`,
  //   name: "Detail Lesson",
  //   component: LessonManageDetailPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH, ROLES.MEMBER],
  // },
  {
    path: `${URLS.GOLF_SWINGS_URL}/:id`,
    name: "Edit Golf Swing",
    component: FormPage,
    layout: BackendLayout,
    allows: [,ROLES.COACH, ROLES.MEMBER],
  },
  {
    path: URLS.GOLF_SWINGS_URL,
    name: "Golf Swings",
    component: HomePage,
    layout: BackendLayout,
    allows: [,ROLES.COACH, ROLES.MEMBER],
  },
];

export default routers;