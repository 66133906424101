import { omit } from 'lodash';
import * as actions from "../Constants/actionTypes";
import { AlertError } from "../../Utils/alertUtils";

const initState = {
    // focus zone list
    [actions.AT_GET_FOCUS_ZONES_LIST]: {
        isLoading: false,
        data: [],
    },
    // focus zone library list
    [actions.AT_GET_FOCUS_ZONES_LIBRARY_LIST]: {
        isLoading: false,
        data: [],
    },
    // focus zone video library list
    [actions.AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST]: {
        isLoading: false,
        data: [],
    },
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        case actions.AT_GLOBAL_REQUEST: {
            return {
                ...state,
                [action.action_type]: {
                    isLoading: true,
                }
            };
        }
        case actions.AT_GLOBAL_SUCCESS: {
            return {
                ...state,
                [action.action_type]: {
                    isLoading: false,
                    data: action.payload
                }
            };
        }
        case actions.AT_GLOBAL_FAILURE: {
            const { error } = action.payload;
            AlertError(error);
            return {
                ...state,
                [action.action_type]: {
                    isLoading: false,
                    data: initState.data
                }
            };
        }
        default:
            return state;
    }
};

export default myReducer;