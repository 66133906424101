import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

export const getAllStudentsOfCoachListApi = () => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH}get-all/`);
};

export const getStudentsOfCoachListApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH}?${query}`);
};

export const getFilterMemberApi = (search) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH}members-filter/?search=${search}`);
};

export const addMemberApi = (data) => {
    return axiosService.postWithToken(urlApis.API_STUDENTS_OF_COACH, data);
};

export const addSendInviteApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_STUDENTS_OF_COACH}send-invite/`, data);
};

export const reSendInviteApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_STUDENTS_OF_COACH}re-send-invite/`, data);
};

export const getStudentsOfCoachDetailApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH}${id}/`);
};

// export const createLessonApi = (data) => {
//     return axiosService.postWithToken(urlApis.API_STUDENTS_OF_COACH, data);
// };

// export const updateLessonApi = (id, data) => {
//     return axiosService.putWithToken(`${urlApis.API_STUDENTS_OF_COACH}${id}/`, data);
// };

export const deleteMemberApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_STUDENTS_OF_COACH}${id}/`);
};

export const setMemberFreeApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_STUDENTS_OF_COACH}set-member-free/`, data);
};

export const getLessonOfStudentListApi = ({id, query}) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH}list-all-lesson-of-member/${id}/?${query}`);
};

export const deleteLessonOfStudentApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_LESSON_OF_STUDENT}${id}/`);
};

export const addLessonOfStudentApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_LESSON_OF_STUDENT}`, data);
};

export const updateStudentApi = (data) => {
    return axiosService.postWithToken(`${urlApis.API_STUDENTS_OF_COACH}update-student/`, data);
};
// 

export const getFilterMyStudentsApi = (search) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH}students-filter-not-pagination/?search=${search}`);
};

export const getAllStudentsListApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH__ALL_STUDENTS}?${query}`);
};

export const getAllCoachsListApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH__ALL_COACHS}?${query}`);
};

export const getCoachDetailApi = (query) => {
    return axiosService.getWithToken(`${urlApis.API_STUDENTS_OF_COACH__GET_COACH}?${query}`);
};