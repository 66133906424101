import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

export const getCountryListApi = () => {
    return axiosService.get(urlApis.API_GET_COUNTRY_LIST);
};

export const getFocusZoneListApi = () => {
    return axiosService.getWithToken(urlApis.API_FOCUS_ZONE_LIST);
};

export const getFocusZoneLibraryListApi = () => {
    return axiosService.getWithToken(urlApis.API_FOCUS_ZONE_LIBRARY_LIST);
};

export const getFocusZoneVideoLibraryListApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_FOCUS_ZONE_VIDEO_LIBRARY_LIST}?${query}`);
};

export const getFocusZoneVideoListApi = (query = '') => {
    return axiosService.getWithToken(`${urlApis.API_FOCUS_ZONE_VIDEO_LIST}?${query}`);
};
