import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// video 
export const getVideoCategoryApi = (query = '') => {
    return axiosService.get(`${urlApis.API_GET_VIDEO_CATEGORY}?${query}`);
    // return axiosService.getWithToken(`${urlApis.API_GET_VIDEO_CATEGORY}?${query}`);
};

export const getVideoCategoryDetailApi = ({slug, query = ''}) => {
    return axiosService.get(`${urlApis.API_GET_VIDEO_CATEGORY}${slug}/?${query}`);
    // return axiosService.getWithToken(`${urlApis.API_GET_VIDEO_CATEGORY}${slug}/?${query}`);
};

export const getVideoDetailApi = ({slug}) => {
    return axiosService.get(`${urlApis.API_VIDEO_DETAILS}${slug}/`);
    // return axiosService.getWithToken(`${urlApis.API_GET_VIDEO_CATEGORY}${slug}/?${query}`);
};

export const getVideoSearchApi = ({query = ''}) => {
    return axiosService.get(`${urlApis.API_VIDEO}?${query}`);
    // return axiosService.getWithToken(`${urlApis.API_GET_VIDEO_CATEGORY}${slug}/?${query}`);
};
