import { lazy } from "react";
import { ROLES } from "Utils/roleAndPermUtils";
import * as URLS from "Utils/routerConstants";

import BackendLayout from "Backend/Layout/BackendLayout";

// Lesson
const NumberOfLessonsPage = lazy(() => import('./NumberOfLessons'));
const LessonOfCoachPage = lazy(() => import('./NumberOfLessons/LessonOfCoach'));
const RevenueBrokenDownByProductPage = lazy(() => import('./RevenueBrokenDownByProduct'));
const UsersCancelledSubscriptionPage = lazy(() => import('./UsersCancelledSubscription'));
const AtRiskReportPage = lazy(() => import('./AtRiskReport'));
const PaymentLogPage = lazy(() => import('./PaymentLogs'));
const PageviewsPage = lazy(() => import('./Pageviews'));
const DashboardPage = lazy(() => import('./Dashboard'));
const MembersPage = lazy(() => import('./Members'));

const routers = [
  // {
  //   path: `${URLS.MY_STUDENT_SWINGS_URL}/:id`,
  //   name: "Golf Swings Of Student",
  //   component: SwingOfStudentPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  // },
  // {
  //   path: URLS.MY_STUDENT__ALL_SWINGS_URL,
  //   name: "Golf Swings",
  //   // component: GolfSwingGetAllPage,
  //   component: MyStudentsPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  // },
  // {
  //   path: URLS.MY_STUDENT__ALL_STUDENTS_URL,
  //   name: "Golf Swings",
  //   // component: GolfSwingGetAllPage,
  //   component: MyStudentsPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  // },
  // {
  //   path: `${URLS.MY_STUDENT_URL}/:id`,
  //   name: "Lesson Of Student",
  //   component: LessonOfStudentPage,
  //   layout: BackendLayout,
  //   allows: [ROLES.COACH],
  //   layoutConfig: {
  //     fluid: true,
  //   }
  // },
  {
    path: `${URLS.REPORTS__NUMBER_OF_LESSON_GIVEN_URL}/:coachId`,
    name: "Reports",
    component: LessonOfCoachPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__NUMBER_OF_LESSON_GIVEN_URL,
    name: "Reports",
    component: NumberOfLessonsPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__REVENUA_BROKEN_DOWN_BY_PRODUCT_URL,
    name: "Reports",
    component: RevenueBrokenDownByProductPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__USERS_CANCELLED_SUBSCRIPTION_URL,
    name: "Reports",
    component: UsersCancelledSubscriptionPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__AT_RISK_REPORT_URL,
    name: "Reports",
    component: AtRiskReportPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__PAYMENT_LOGS_URL,
    name: "Reports",
    component: PaymentLogPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__PAGEVIEWS_URL,
    name: "Reports",
    component: PageviewsPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS__MEMBERS_URL,
    name: "Reports",
    component: MembersPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
  {
    path: URLS.REPORTS_URL,
    name: "Reports",
    component: DashboardPage,
    layout: BackendLayout,
    allows: [ROLES.COACH],
  },
];

export default routers;