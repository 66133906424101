import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// user
export const changePasswordApi = (data) => {
    return axiosService.postWithToken(urlApis.API_CHANGE_PASSWORD_POST, data);
};

export const updateProfileApi = (data) => {
    return axiosService.postWithToken(urlApis.API_UPDATE_PROFILE_POST, data);
};

export const getUserProfileApi = () => {
    return axiosService.getWithToken(urlApis.API_USER_PROFILE_GET);
};

export const getUserDashboardApi = () => {
    return axiosService.getWithToken(urlApis.API_USER_DASHBOARD_GET);
};
