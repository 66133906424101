import * as actions from "../Constants/actionTypes";


// get payment log
export const getPaymentLogFailureAction = (payload) => ({
    type: actions.AT_GET_PAYMENT_LOG_FAILURE,
    payload,
});

export const getPaymentLogSuccessAction = (payload) => ({
    type: actions.AT_GET_PAYMENT_LOG_SUCCESS,
    payload,
});

export const getPaymentLogRequestAction = (payload) => ({
    type: actions.AT_GET_PAYMENT_LOG_REQUEST,
    payload,
});

// get practice membership
export const getPracticeMembershipFailureAction = (payload) => ({
    type: actions.AT_GET_PRACTICE_MEMBERSHIP_FAILURE,
    payload,
});

export const getPracticeMembershipSuccessAction = (payload) => ({
    type: actions.AT_GET_PRACTICE_MEMBERSHIP_SUCCESS,
    payload,
});

export const getPracticeMembershipRequestAction = (payload) => ({
    type: actions.AT_GET_PRACTICE_MEMBERSHIP_REQUEST,
    payload,
});

// get lesson membership
export const getLessonMembershipFailureAction = (payload) => ({
    type: actions.AT_GET_LESSON_MEMBERSHIP_FAILURE,
    payload,
});

export const getLessonMembershipSuccessAction = (payload) => ({
    type: actions.AT_GET_LESSON_MEMBERSHIP_SUCCESS,
    payload,
});

export const getLessonMembershipRequestAction = (payload) => ({
    type: actions.AT_GET_LESSON_MEMBERSHIP_REQUEST,
    payload,
});
