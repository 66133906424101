import React, { useState, useEffect } from 'react'
import { useSelector } from "react-redux";
import { get } from "lodash";
import { withRouter } from "react-router-dom";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CFade,
  CInputGroup,
  CInput,
  CInputGroupAppend,
  CButton,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

// routes config
import * as URLS from "../../../Utils/routerConstants";
import logoIcon from "../../../Assets/images/golftec-icon.png"
import "./TheHeader.scss";
import { getQueryParams } from "../../../Utils/globalUtils";
import { isMember, isCoach, checkMembership, PROFILE_MEMBERSHIP_TYPE } from "../../../Utils/roleAndPermUtils";


import {
  TheHeaderDropdown,
} from './index'

const TheHeader_VideoCourses = (props) => {
  const {video_course_is_paid} = useSelector((state) => state.user.profile);
  const [searchInput, setSearchInput] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);

  // const asideShow = props.theme.asideShow;
  // const darkMode = props.theme.darkMode;
  const sidebarShow = props.theme.sidebarShow;

  const profile = get(props, 'profile')
  const profile_profile = get(props, 'profile.profile')
  const groups = get(props, 'profile.groups', []);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    props.setTheme({ type: 'set', sidebarShow: val })
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    props.setTheme({ type: 'set', sidebarShow: val })
  }

  const handleSearch = (e) => {
    e.preventDefault();
    searchInput && props.history.push(`${URLS.VIDEO_CATEGORIES_URL}?search=${searchInput}`);
  }

  const queryParams = getQueryParams(get(props, 'location.search'));
  const search = get(queryParams, 'search')

  useEffect(() => {
    if (search && props.location.pathname === URLS.VIDEO_CATEGORIES_URL) {
      setToggleSearch(true);
      setSearchInput(search);
    }
  }, [search])

  // const isPaid = !!checkMembership(PROFILE_MEMBERSHIP_TYPE.LESSON, profile_profile);

  const hostNameLink = URLS.DASHBOARD_URL;

  return (
    <CHeader withSubheader>
      {
        !sidebarShow && <CHeaderNav className="d-md-down-none">
          <CHeaderNavItem className="p-0 pl-2" >
            <CHeaderNavLink href={hostNameLink}>
              <img src={logoIcon} className="c-sidebar-brand-minimized" height={35} alt="" />
            </CHeaderNavLink>
          </CHeaderNavItem>
        </CHeaderNav>
      }

      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to={hostNameLink}>
        <img src={logoIcon} className="c-sidebar-brand-minimized" height={35} alt="" />
        {/* <CIcon name="logo" height="48" alt="Logo" /> */}
      </CHeaderBrand>      

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavLink href={ hostNameLink }>Dashboard</CHeaderNavLink>
        {/* {!toggleSearch && <div onClick={() => setToggleSearch(true)} role="button"><CIcon name="cil-magnifying-glass" /></div>}
        <CFade timeout={100} in={toggleSearch}>
          <form onSubmit={handleSearch}>
            <CInputGroup>
              <CInput type="text" id="videoSearch" name="videoSearch" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Video search" autoComplete="off" />
              <CInputGroupAppend>
                <CButton type="submit" color="primary"><CIcon name="cil-magnifying-glass" /></CButton>
              </CInputGroupAppend>
            </CInputGroup>
          </form>
        </CFade> */}
        {/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem> 
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink>Settings</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/profile">Profile</CHeaderNavLink>
        </CHeaderNavItem> */}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        {/* {
          !!isMember(groups) && <CHeaderNavItem className="px-3" >
            <CHeaderNavLink to={URLS.PAYMENT_HISTORY_URL} className={video_course_is_paid ? 'text-success' : 'text-danger'}><CIcon name={video_course_is_paid ? 'cil-check-circle' : 'cil-x-circle'} className="mr-1" /> {video_course_is_paid ? 'Paid' : 'Unpaid'}(Video course)</CHeaderNavLink>
          </CHeaderNavItem>
        }
        {
          !!isCoach(groups) && <CHeaderNavItem className="px-3" >
            <CHeaderNavLink to={URLS.PAYMENT_HISTORY_FOR_COACH_URL} className={video_course_is_paid ? 'text-success' : 'text-danger'}><CIcon name={video_course_is_paid ? 'cil-check-circle' : 'cil-x-circle'} className="mr-1" /> {video_course_is_paid ? 'Paid' : 'Unpaid'}(Video course)</CHeaderNavLink>
          </CHeaderNavItem>
        } */}
        {/* <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={() => props.setTheme({ type: 'set', darkMode: !darkMode })}
          title="Toggle Light/Dark Mode"
        >
          {
            darkMode ?
              <CIcon name="cil-sun" className="" alt="CoreUI Icons Sun" /> :
              <CIcon name="cil-moon" className="" alt="CoreUI Icons Moon" />
          }
        </CToggler> */}
        {/* <TheHeaderDropdownNotif /> */}
        {/* <TheHeaderDropdownTasks /> */}
        {/* <TheHeaderDropdownMssg /> */}
        {profile && profile.email && <TheHeaderDropdown {...props} />}
        {/* <CToggler
          inHeader
          className="d-md-down-none"
          onClick={() => props.setTheme({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon className="mr-2" size="lg" name="cil-applications-settings" />
        </CToggler> */}
      </CHeaderNav>

      {/* <CSubheader className="px-3 justify-content-between">
        <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} />
        <div className="d-md-down-none mfe-2 c-subheader-nav">
          <CLink
            className="c-subheader-nav-link"
            aria-current="page"
            to={URLS.DASHBOARD_URL}
          >
            <CIcon name="cil-speedometer" alt="Dashboard" />&nbsp;Dashboard
            </CLink>
          <Link className="c-subheader-nav-link" to={URLS.PROFILE_URL}>
            <CIcon name="cil-settings" alt="Profile" />&nbsp;Profile
            </Link>
        </div> 
      </CSubheader> */}
    </CHeader>
  )
}

export default withRouter(TheHeader_VideoCourses);
