import { lazy } from "react";

import * as URLS from "../Utils/routerConstants";

const FrontendLayout = lazy(() => import('../Frontend/Layout/FrontendLayout'));
const FrontendLayoutAccount = lazy(() => import('../Frontend/Layout/FrontendLayoutAccount'));
const FrontendDemoLayout = lazy(() => import('./../Frontend/Layout/FrontendDemoLayout'));
const BackendLayout = lazy(() => import('../Frontend/Layout/BackendLayout'));

const HomePage = lazy(() => import('../Frontend/Pages/Home/HomePage'));
const LoginPage = lazy(() => import('../Frontend/Pages/Account/Login/LoginPage'));
const ActiveAccountPage = lazy(() => import('../Frontend/Pages/Account/ActiveAccount/ActiveAccountPage'));
const ResendActivationLinkPage = lazy(() => import('../Frontend/Pages/Account/ResendActivationLink/ResendActivationLinkPage'));
const ForgotPasswordPage = lazy(() => import('../Frontend/Pages/Account/ForgotPassword/ForgotPasswordPage'));
const ResetPasswordPage = lazy(() => import('../Frontend/Pages/Account/ResetPassword/ResetPasswordPage'));
const RegisterPage = lazy(() => import('../Frontend/Pages/Account/Register/RegisterPage'));
const TermPage = lazy(() => import('../Frontend/Pages/Term/TermPage'));
const DemoPage = lazy(() => import('../Frontend/Pages/Demo/DemoPage.component'));

// video
const VideoCategoriesPage = lazy(() => import('../Backend/Pages/Video'));
const VideoListPage = lazy(() => import('../Backend/Pages/Video/VideoList'));

const routers = [
  {
    exact: true,
    path: "/",
    component: HomePage,
    layout: FrontendLayout
  },
  
  // Video for member only
  // {
  //   path: `${URLS.VIDEO_CATEGORIES_URL}/:slug/:videoSlug?`,
  //   name: "Video Detail",
  //   component: VideoListPage,
  //   layout: BackendLayout,
  // },
  // {
  //   path: URLS.VIDEO_CATEGORIES_URL,
  //   name: "Golf Video Library",
  //   component: VideoCategoriesPage,
  //   layout: BackendLayout,
  // },

  {
    path: "/login",
    name: "Please Sign In - GolfTec Member Section",
    component: LoginPage,
    layout: FrontendLayoutAccount
  },
  {
    path: "/forgot-password",
    name: "Forgot Password",
    component: ForgotPasswordPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/reset-password",
    name: "Reset Password",
    component: ResetPasswordPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/register",
    name: "Register",
    component: RegisterPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/active-account",
    component: ActiveAccountPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/resend-link-active",
    component: ResendActivationLinkPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/term",
    component: TermPage,
    layout: FrontendLayoutAccount,
  },
  {
    path: "/demo-page",
    component: DemoPage,
    layout: FrontendDemoLayout,
  }
];

export default routers;