import React, { useState, useEffect } from 'react'
import { get, find } from "lodash";
import { withRouter, Link } from "react-router-dom";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CFade,
  CInputGroup,
  CInput,
  CInputGroupAppend,
  CButton,
  CSubheader,
  CBreadcrumbRouter,
  CLink
} from '@coreui/react'
import CIcon from '@coreui/icons-react'

// routes config
import * as URLS from "../../../Utils/routerConstants";
import logoIcon from "../../../Assets/images/golftec-icon.png"
import "./TheHeader.scss";
import { getQueryParams } from "../../../Utils/globalUtils";
import { isMember, checkMembership, PROFILE_MEMBERSHIP_TYPE } from "../../../Utils/roleAndPermUtils";
import TheBreadcrumbs from "./TheBreadcrumbs"

import {
  TheHeaderDropdown,
} from './index'

const TheHeader = (props) => {

  const [searchInput, setSearchInput] = useState('');
  const [toggleSearch, setToggleSearch] = useState(false);

  // const asideShow = props.theme.asideShow;
  // const darkMode = props.theme.darkMode;
  const sidebarShow = props.theme.sidebarShow;

  const profile = get(props, 'profile')
  const profile_profile = get(props, 'profile.profile')
  const groups = get(props, 'profile.groups', []);

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    props.setTheme({ type: 'set', sidebarShow: val })
  }

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    props.setTheme({ type: 'set', sidebarShow: val })
  }

  const handleSearch = (e) => {
    e.preventDefault();
    searchInput && props.history.push(`${URLS.VIDEO_CATEGORIES_URL}?search=${searchInput}`);
  }

  const queryParams = getQueryParams(get(props, 'location.search'));
  const search = get(queryParams, 'search')

  useEffect(() => {
    if (search && props.location.pathname === URLS.VIDEO_CATEGORIES_URL) {
      setToggleSearch(true);
      setSearchInput(search);
    }
  }, [search])

  const isPaid = !!checkMembership(PROFILE_MEMBERSHIP_TYPE.LESSON, profile_profile);
  
  return (
    <CHeader withSubheader>
      {
        !sidebarShow && <CHeaderNav className="d-md-down-none">
          <CHeaderNavItem className="p-0 pl-2" >
            <CHeaderNavLink href={URLS.MAIN_WEBSITE_URL}>
              <img src={logoIcon} className="c-sidebar-brand-minimized" height={35} alt="" />
            </CHeaderNavLink>
          </CHeaderNavItem>
        </CHeaderNav>
      }

      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="ml-0 mr-auto d-lg-none" to={URLS.MAIN_WEBSITE_URL}>
        <img src={logoIcon} className="c-sidebar-brand-minimized" height={35} alt="" />
        {/* <CIcon name="logo" height="48" alt="Logo" /> */}
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        {!toggleSearch && <div onClick={() => setToggleSearch(true)} role="button"><CIcon name="cil-magnifying-glass" /></div>}
        <CFade timeout={100} in={toggleSearch}>
          <form onSubmit={handleSearch}>
            <CInputGroup>
              <CInput type="text" id="videoSearch" name="videoSearch" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} placeholder="Video search" autoComplete="off" />
              <CInputGroupAppend>
                <CButton type="submit" color="primary"><CIcon name="cil-magnifying-glass" /></CButton>
              </CInputGroupAppend>
            </CInputGroup>
          </form>
        </CFade>
        {/* <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem> 
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink>Settings</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3" >
          <CHeaderNavLink to="/profile">Profile</CHeaderNavLink>
        </CHeaderNavItem> */}
      </CHeaderNav>

      <CHeaderNav className="px-3">
        {/* {
          !!isMember(groups) && <CHeaderNavItem className="px-3" >
            <CHeaderNavLink to={isPaid ? URLS.PAYMENT_HISTORY_URL : URLS.PAYMENT_OPTIONS_URL} className={isPaid ? 'text-success' : 'text-danger'}><CIcon name={isPaid ? 'cil-check-circle' : 'cil-x-circle'} className="mr-1" /> {isPaid ? 'Paid' : 'Unpaid'}</CHeaderNavLink>
          </CHeaderNavItem>
        } */}
        {/* <CToggler
          inHeader
          className="ml-3 d-md-down-none"
          onClick={() => props.setTheme({ type: 'set', darkMode: !darkMode })}
          title="Toggle Light/Dark Mode"
        >
          {
            darkMode ?
              <CIcon name="cil-sun" className="" alt="CoreUI Icons Sun" /> :
              <CIcon name="cil-moon" className="" alt="CoreUI Icons Moon" />
          }
        </CToggler> */}
        {/* <TheHeaderDropdownNotif /> */}
        {/* <TheHeaderDropdownTasks /> */}
        {/* <TheHeaderDropdownMssg /> */}
        {profile && profile.email && <TheHeaderDropdown {...props} />}
        {/* <CToggler
          inHeader
          className="d-md-down-none"
          onClick={() => props.setTheme({ type: 'set', asideShow: !asideShow })}
        >
          <CIcon className="mr-2" size="lg" name="cil-applications-settings" />
        </CToggler> */}
      </CHeaderNav>

      <TheBreadcrumbs {...props}/>
      {/* {
        <CSubheader className="px-3 justify-content-between">
          <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={breadcrumbRouter} />
        </CSubheader>
      } */}
    </CHeader>
  )
}

export default withRouter(TheHeader);
