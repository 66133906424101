import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

// auth

export const resetPasswordApi = (data) => {
    // return axiosService.postWithTokenOnHeaders(urlApis.API_RESET_PASSWORD_POST, data);
    return axiosService.post(urlApis.API_RESET_PASSWORD_POST, data);
};

export const activeAccountApi = (data) => {
    // return axiosService.postWithTokenOnHeaders(urlApis.API_ACTIVE_ACCOUNT_POST, data);
    return axiosService.post(urlApis.API_ACTIVE_ACCOUNT_POST, data);
};

export const resendActivationLinkApi = (data) => {
    return axiosService.post(urlApis.API_RESEND_LINK_ACTIVATION_POST, data);
};

export const forgotPasswordApi = (data) => {
    return axiosService.post(urlApis.API_FORGOT_PASSWORD_POST, data);
};

export const signupApi = (data) => {
    return axiosService.post(urlApis.API_SIGNUP_POST, data);
};

export const loginApi = (data) => {
    return axiosService.postLogin(urlApis.API_LOGIN_POST, data);
};

export const getInviteInfoApi = ({query = ''}) => {
    return axiosService.get(`${urlApis.API_SIGNUP_GET}?${query}`);
};

export const getCheckAccessToken = (query = '') => {
    return axiosService.get(`${urlApis.API_RESET_PASSWORD_POST}?${query}`);
};