import {
    TOKEN_ACCESS_CODE,
    TOKEN_REFRESH_CODE
} from "./../Constants/localStorageConstants";
import jwt from "jwt-decode";
import SecureStorage from 'secure-web-storage';

import { STORE_REDUX } from "./../Constants/localStorageConstants";

var CryptoJS = require("crypto-js");
var SECRET_KEY = "localStorageUtils.js";

export const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
    }
});

export const getExpiredTimeFromAccessToken = () => {
    var expired_time = 0;
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        expired_time = jwt(access_token).exp;
    } catch (error) {}
    return expired_time;
};

export const getUserRolesFromAccessToken = () => {
    var roles= '';
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        roles = jwt(access_token).roles;
    } catch (error) {}
    return roles;
};

export const getUserEmailFromAccessToken = () => {
    var email= '';
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        email = jwt(access_token).email;
    } catch (error) {}
    return email;
};

export const getUserLastNameFromAccessToken = () => {
    var last_name= '';
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        last_name = jwt(access_token).last_name;
    } catch (error) {}
    return last_name;
};

export const getUserFirstNameFromAccessToken = () => {
    var first_name= '';
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        first_name = jwt(access_token).first_name;
    } catch (error) {}
    return first_name;
};

export const getUserFullNameFromAccessToken = () => {
    var full_name= '';
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        full_name = jwt(access_token).first_name + ' ' + jwt(access_token).last_name;
    } catch (error) {}
    return full_name;
};

export const getUserIdFromAccessToken = () => {
    var user_id = 0;
    try {
        let access_token = secureStorage.getItem(TOKEN_ACCESS_CODE);
        user_id = jwt(access_token).user_id;
    } catch (error) {}
    return user_id;
};

export const getToken = () => {
    return secureStorage.getItem(TOKEN_ACCESS_CODE);
};

export const setToken = ({ access }) => {
    secureStorage.setItem(TOKEN_ACCESS_CODE, access);
};

export const setTokenRefresh = ({ refresh }) => {
    secureStorage.setItem(TOKEN_REFRESH_CODE, refresh);
};

export const removeToken = () => {
    secureStorage.removeItem(TOKEN_ACCESS_CODE);  
    secureStorage.removeItem(TOKEN_REFRESH_CODE);  
};

export const removeStorage = () => {
    secureStorage.removeItem(STORE_REDUX);
};
