// import { snackbarService } from "uno-material-ui";
import { toast } from 'react-toastify';

const toast_option = {
    position: toast.POSITION.BOTTOM_RIGHT,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
    limit: 1,
}

export const AlertSuccess = message => {
    return toast.success(message, toast_option);
    // return snackbarService.showSnackbar(message, "success");
};

export const AlertError = message => {
    return toast.error(message, toast_option);
    // return snackbarService.showSnackbar(message, "error");
};

export const AlertWarning = message => {
    return toast.warn(message, toast_option);
    // return snackbarService.showSnackbar(message, "warning");
};

export const AlertInfo = message => {
    return toast.info(message, toast_option);
    // return snackbarService.showSnackbar(message, "info");
};
