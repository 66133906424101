import { fork } from "redux-saga/effects";
import helloSaga from "./helloSaga";
import authSaga from "./authSaga";
import userSaga from "./userSaga";
import paymentSaga from "./paymentSaga";
import videoSaga from "./videoSaga";
import librarySaga from "./librarySaga";
import lessonSaga from "./lessonSaga";
import myStudentsSaga from "./myStudentsSaga";
import golfSwingSaga from "./golfSwingSaga";
import globalSaga from "./globalSaga";

export default function* rootSaga() {
    yield fork(globalSaga); 
    yield fork(golfSwingSaga); 
    yield fork(myStudentsSaga); 
    yield fork(lessonSaga); 
    yield fork(librarySaga); 
    yield fork(videoSaga); 
    yield fork(paymentSaga); 
    yield fork(userSaga); 
    yield fork(authSaga); 
    yield fork(helloSaga);    
}


