import React, { Fragment } from "react";
import ContentHome from "./Content";

const BackendLayoutNoContent = (props) => (
    <Fragment>
        <ContentHome children={props.children} />
    </Fragment>
);

export default BackendLayoutNoContent;
