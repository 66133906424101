import * as urlApis from "./constantsApi";
import axiosService from "../Utils/axiosServiceUtils";

export const getLessonApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_LESSON}?${data}`);
};

export const getLessonPostApi = ({ query, url = "" }) => {
    return axiosService.getWithToken(`${urlApis.API_LESSON}get-post/${url}?${query}`);
};

export const getAlLessonCategoryApi = (data) => {
    return axiosService.getWithToken(`${urlApis.API_LESSON}categories/`);
};

export const getLessonDetailApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_LESSON}${id}/`);
};

export const createLessonApi = (data) => {
    return axiosService.postWithToken(urlApis.API_LESSON, data);
};

export const updateLessonApi = (id, data) => {
    return axiosService.putWithToken(`${urlApis.API_LESSON}${id}/`, data);
};

export const deleteLessonApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_LESSON}${id}/`);
};

export const getTagsLessonApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_LESSON}get-tags/`);
};

// ==============================

export const getExerciseLibraryApi = ({ query, url = "" }) => {
    return axiosService.getWithToken(`${urlApis.API_EXERCISE_LIBRARY}${url}?${query}`);
};

export const getExerciseLibraryDetailApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_EXERCISE_LIBRARY}${id}/`);
};

export const createExerciseLibraryApi = (data) => {
    return axiosService.postWithToken(urlApis.API_EXERCISE_LIBRARY, data);
};

export const updateExerciseLibraryApi = (id, data) => {
    return axiosService.putWithToken(`${urlApis.API_EXERCISE_LIBRARY}${id}/`, data);
};

export const deleteExerciseLibraryApi = (id) => {
    return axiosService.deleteWithToken(`${urlApis.API_EXERCISE_LIBRARY}${id}/`);
};

// ============================
export const getSevenStageSwingLibraryCategoryApi = () => {
    return axiosService.getWithToken(`${urlApis.API_SEVEN_STAGE_SWING_LIBRARY}get-all-category/`);
};

export const getSevenStageSwingLibraryApi = ({ query, url = "" }) => {
    return axiosService.getWithToken(`${urlApis.API_SEVEN_STAGE_SWING_LIBRARY}${url}?${query}`);
};

export const getSevenStageSwingLibraryDetailApi = (id) => {
    return axiosService.getWithToken(`${urlApis.API_SEVEN_STAGE_SWING_LIBRARY}${id}/`);
};