import { combineReducers } from "redux";
import helloReducer from "./helloReducer";
import themeReducer from "./themeReducer";
import authentication from "./authReducer";
import paymentReducer from "./paymentReducer";
import categoriesReducer from "./categoriesReducer";
import categoriesDetailReducer from "./categoriesDetailReducer";
import imageLibraryReducer from "./imageLibraryReducer";
import videoLibraryReducer from "./videoLibraryReducer";
import lessonManageReducer from "./lessonManageReducer";
import lessonCategoryReducer from "./lessonCategoryReducer";
import lessonPostReducer from "./lessonPostReducer";
import exerciseLibraryReducer from "./exerciseLibraryReducer";
import sevenStageSwingLibraryCategoryReducer from "./sevenStageSwingLibraryCategoryReducer";
import sevenStageSwingLibraryReducer from "./sevenStageSwingLibraryReducer";
import myStudentsReducer from "./myStudentsReducer";
import lessonOfStudentReducer from "./lessonOfStudentReducer";
import userDashboardReducer from "./userDashboardReducer";
import videoDetailReducer from "./videoDetailReducer";
import videoSearchReducer from "./videoSearchReducer";
import myStudentsListAllReducer from "./myStudentsListAllReducer";
import user from "./userReducer";
import golfSwingReducer from "./golfSwingReducer";
import golfSwingTokenReducer from "./golfSwingTokenReducer";
import golfSwingGetAllReducer from "./golfSwingGetAllReducer";
import myStudentDetailReducer from "./myStudentDetailReducer";
import myStudentsListAllStudentsReducer from "./myStudentsListAllStudentsReducer";
import globalReducer from "./globalReducer";

const rootReducer = combineReducers({
    globalReducer,
    myStudentsListAllStudentsReducer,
    myStudentDetailReducer,
    golfSwingGetAllReducer,
    golfSwingTokenReducer,
    golfSwingReducer,
    myStudentsListAllReducer,
    videoSearchReducer,
    videoDetailReducer,
    userDashboardReducer,
    lessonOfStudentReducer,
    myStudentsReducer,
    sevenStageSwingLibraryReducer,
    sevenStageSwingLibraryCategoryReducer,
    exerciseLibraryReducer,
    lessonPostReducer,
    lessonCategoryReducer,
    lessonManageReducer,
    videoLibraryReducer,
    imageLibraryReducer,
    categoriesDetailReducer,
    categoriesReducer,
    paymentReducer,
    themeReducer,
    helloReducer,
    authentication,
    user,
});

export default rootReducer;
