import { lazy } from "react";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import { LESSON_POST_URL } from "../../../Utils/routerConstants";

import BackendLayout from "../../../Backend/Layout/BackendLayout";

// Lesson
// import LessonPostPage from "./index";
// import LessonPostDetail from "./Detail";
const LessonPostPage = lazy(() => import('./index'));
const LessonPostDetail = lazy(() => import('./Detail'));

const routers = [
  {
    path: `${LESSON_POST_URL}/:slug/:lessonSlug`,
    name: "Lesson Detail",
    component: LessonPostDetail,
    layout: BackendLayout,
    allows: [ROLES.COACH, ROLES.MEMBER],
  },
  {
    path: `${LESSON_POST_URL}/:slug`,
    name: "Lesson Post By Category",
    component: LessonPostPage,
    layout: BackendLayout,
    allows: [ROLES.COACH, ROLES.MEMBER],
  },
  {
    path: LESSON_POST_URL,
    name: "Lesson Post",
    component: LessonPostPage,
    layout: BackendLayout,
    allows: [ROLES.COACH, ROLES.MEMBER],
  },
];

export default routers;