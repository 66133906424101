import React, { Component } from "react";

class ContentHome extends Component {
    render() {
        return (
            <div className="container">
                <div className="">{this.props.children}</div>
            </div>
        );
    }
}

export default ContentHome;
