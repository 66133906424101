import * as actions from "../Constants/actionTypes";

// change password

export const changePasswordFailureAction = (payload) => ({
    type: actions.AT_CHANGE_PASSWORD_FAILURE,
    payload,
});

export const changePasswordSuccessAction = (payload) => ({
    type: actions.AT_CHANGE_PASSWORD_SUCCESS,
    payload,
});

export const changePasswordRequestAction = (data) => ({
    type: actions.AT_CHANGE_PASSWORD_REQUEST,
    payload: { data },
});

// update profile

export const updateProfileFailureAction = (payload) => ({
    type: actions.AT_UPDATE_PROFILE_FAILURE,
    payload,
});

export const updateProfileSuccessAction = (payload) => ({
    type: actions.AT_UPDATE_PROFILE_SUCCESS,
    payload,
});

export const updateProfileRequestAction = (data) => ({
    type: actions.AT_UPDATE_PROFILE_REQUEST,
    payload: { data },
});

// get profile
export const setUserProfileToNullAction = () => ({
    type: actions.AT_USER_SET_PROFILE_NULL,
});

export const getUserProfileFailureAction = (playload) => ({
    type: actions.AT_USER_GET_PROFILE_FAILURE,
    playload,
});

export const getUserProfileSuccessAction = (playload) => ({
    type: actions.AT_USER_GET_PROFILE_SUCCESS,
    playload,
});

export const getUserProfileRequestAction = (data) => ({
    type: actions.AT_USER_GET_PROFILE_REQUEST,
    payload: { data },
});

// get dashboard
export const getUserDashboardFailureAction = (payload) => ({
    type: actions.AT_GET_USER_DASHBOARD_FAILURE,
    payload,
});

export const getUserDashboardSuccessAction = (payload) => ({
    type: actions.AT_GET_USER_DASHBOARD_SUCCESS,
    payload,
});

export const getUserDashboardRequestAction = (data) => ({
    type: actions.AT_GET_USER_DASHBOARD_REQUEST,
    payload: { data },
});

// Clear unread message
export const clearUnreadMessageAction = () => ({
    type: actions.AT_CLEAR_UNREAD_MESSAGE,
});

// Count message unread
export const countMessageUnreadFailureAction = (payload) => ({
    type: actions.AT_COUNT_MESSAGE_UNREAD_FAILURE,
    payload,
});

export const countMessageUnreadSuccessAction = (payload) => ({
    type: actions.AT_COUNT_MESSAGE_UNREAD_SUCCESS,
    payload,
});

export const countMessageUnreadRequestAction = () => ({
    type: actions.AT_COUNT_MESSAGE_UNREAD_REQUEST
});
