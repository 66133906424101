import * as actions from "../Constants/actionTypes";


// get category
export const getCategoryFailureAction = (payload) => ({
    type: actions.AT_GET_VIDEO_CATEGORY_FAILURE,
    payload,
});

export const getCategorySuccessAction = (payload) => ({
    type: actions.AT_GET_VIDEO_CATEGORY_SUCCESS,
    payload,
});

export const getCategoryRequestAction = (payload) => ({
    type: actions.AT_GET_VIDEO_CATEGORY_REQUEST,
    payload,
});

// get category detail
export const getCategoryDetailFailureAction = (payload) => ({
    type: actions.AT_GET_VIDEO_CATEGORY_DETAIL_FAILURE,
    payload,
});

export const getCategoryDetailSuccessAction = (payload) => ({
    type: actions.AT_GET_VIDEO_CATEGORY_DETAIL_SUCCESS,
    payload,
});

export const getCategoryDetailRequestAction = (payload) => ({
    type: actions.AT_GET_VIDEO_CATEGORY_DETAIL_REQUEST,
    payload,
});

// get video detail
export const getVideoDetailFailureAction = (payload) => ({
    type: actions.AT_GET_VIDEO_DETAIL_FAILURE,
    payload,
});

export const getVideoDetailSuccessAction = (payload) => ({
    type: actions.AT_GET_VIDEO_DETAIL_SUCCESS,
    payload,
});

export const getVideoDetailRequestAction = (payload) => ({
    type: actions.AT_GET_VIDEO_DETAIL_REQUEST,
    payload,
});

// get video search
export const getVideoSearchFailureAction = (payload) => ({
    type: actions.AT_GET_VIDEO_SEARCH_FAILURE,
    payload,
});

export const getVideoSearchSuccessAction = (payload) => ({
    type: actions.AT_GET_VIDEO_SEARCH_SUCCESS,
    payload,
});

export const getVideoSearchRequestAction = (payload) => ({
    type: actions.AT_GET_VIDEO_SEARCH_REQUEST,
    payload,
});
