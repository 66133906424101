import React from "react";
import * as URLS from "../../../Utils/routerConstants";
import { ROLES } from "../../../Utils/roleAndPermUtils";
import CIcon from '@coreui/icons-react'

const default_nav = [
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Dashboard',
  //   to: URLS.DASHBOARD_URL,
  //   icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
  //   className: 'dashboard-item'
  //   // badge: {
  //   //   color: 'info',
  //   //   text: 'NEW',
  //   // }
  // },

  // {
  //   _tag: 'CSidebarNavTitle',
  // },
  // {
  //   id: "golf_video",
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Golf Video',
  //   route: URLS.VIDEO_CATEGORIES_URL,
  //   icon: 'cil-video',
  //   _children: []
  // },
];


const coach_group = [
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'My Golf Lessons',
  //   to: URLS.LESSON_MANAGE_URL,
  //   icon: 'cil-education',
  //   allows: [ROLES.COACH, ROLES.MEMBER],
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Golf Swings',
  //   to: URLS.GOLF_SWINGS_URL,
  //   icon: 'cil-badge',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Golf Video Courses',
  //   to: URLS.VIDEO_COURSE_CATEGORIES_URL,
  //   icon: 'cil-video',
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'My Students',
    to: URLS.MY_STUDENT_URL,
    icon: 'cil-people',
    allows: [ROLES.COACH],
  },
  {
    id: "golf_video",
    _tag: 'CSidebarNavDropdown',
    name: 'Golf Video',
    route: URLS.VIDEO_CATEGORIES_URL,
    icon: 'cil-video',
    _children: []
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Reports',
    route: URLS.REPORTS_URL,
    icon: 'cil-calendar-check',
    _children: [
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Dashboard',
        to: URLS.REPORTS_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Coach Lessons',
        to: URLS.REPORTS__NUMBER_OF_LESSON_GIVEN_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Revenue by Product',
        to: URLS.REPORTS__REVENUA_BROKEN_DOWN_BY_PRODUCT_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Cancelled Subscripions',
        to: URLS.REPORTS__USERS_CANCELLED_SUBSCRIPTION_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'At Risk Report',
        to: URLS.REPORTS__AT_RISK_REPORT_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Payment Logs',
        to: URLS.REPORTS__PAYMENT_LOGS_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Members',
        to: URLS.REPORTS__MEMBERS_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Pageviews',
        to: URLS.REPORTS__PAGEVIEWS_URL,
      },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'Media Library',
    route: URLS.LIBRARY_URL,
    icon: 'cil-library',
    _children: [
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Image Library',
        to: URLS.IMAGES_LIBRARY_URL,
        // icon: 'cil-image',
        allows: [ROLES.COACH, ROLES.MEMBER],
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Video Library',
        to: URLS.VIDEOS_LIBRARY_URL,
        // icon: 'cil-video',
        allows: [ROLES.COACH],
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Exercise Library',
        to: URLS.EXERCISE_LIBRARY_URL,
        // icon: 'cil-golf-alt',
        allows: [ROLES.COACH],
      },
      // {
      //   _tag: 'CSidebarNavItem',
      //   name: 'Seven Stage Swing Library',
      //   to: URLS.SEVEN_STAGE_SWING_LIBRARY_URL,
      //   // icon: 'cil-golf-alt',
      //   allows: [ROLES.COACH],
      // },
    ]
  },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'My Account',
    route: URLS.MY_ACCOUNT_URL,
    icon: 'cil-user',
    _children: [
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Profile',
        to: URLS.PROFILE_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Security (Change password)',
        to: URLS.CHANGE_PASSWORD_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Payment History',
        to: URLS.PAYMENT_HISTORY_FOR_COACH_URL,
      },
    ]
  },
]

const member_group = [
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Golf Lessons',
  //   to: URLS.LESSON_POST_URL,
  //   icon: 'cil-education',
  // },
  {
    _tag: 'CSidebarNavItem',
    name: 'My Golf Lessons',
    to: URLS.LESSON_MANAGE_URL,
    icon: 'cil-education',
  },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Golf Swings',
  //   to: URLS.GOLF_SWINGS_URL,
  //   icon: 'cil-badge',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Golf Video Courses',
  //   to: URLS.VIDEO_COURSE_CATEGORIES_URL,
  //   icon: 'cil-video',
  // },
  {
    id: "golf_video",
    _tag: 'CSidebarNavDropdown',
    name: 'Golf Video',
    route: URLS.VIDEO_CATEGORIES_URL,
    icon: 'cil-video',
    _children: []
  },
  // {
  //   _tag: 'CSidebarNavDropdown',
  //   name: 'Media Library',
  //   route: URLS.LIBRARY_URL,
  //   icon: 'cil-library',
  //   _children: [
  //     {
  //       _tag: 'CSidebarNavItemCus',
  //       name: 'Image Library',
  //       to: URLS.IMAGES_LIBRARY_URL,
  //       // icon: 'cil-image',
  //       allows: [ROLES.COACH, ROLES.MEMBER],
  //     },
  //   ]
  // },
  {
    _tag: 'CSidebarNavDropdown',
    name: 'My Account',
    route: URLS.MY_ACCOUNT_URL,
    icon: 'cil-user',
    _children: [
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Membership',
        to: URLS.PAYMENT_OPTIONS_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Billing & Payments',
        to: URLS.BILLING_PAYMENT_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Profile',
        to: URLS.PROFILE_URL,
      },
      {
        _tag: 'CSidebarNavItemCus',
        name: 'Security (Change password)',
        to: URLS.CHANGE_PASSWORD_URL,
      },
      // {
      //   _tag: 'CSidebarNavItemCus',
      //   name: 'Payment History',
      //   to: URLS.PAYMENT_HISTORY_URL,
      // },
    ]
  },
]


export default {
  default_nav,
  coach_group,
  member_group
}