import * as actions from "../Constants/actionTypes";


export const getFocusZoneListFailureAction = (payload) => ({
    type: actions.AT_GLOBAL_FAILURE,
    action_type: actions.AT_GET_FOCUS_ZONES_LIST,
    payload,
});

export const getFocusZoneListSuccessAction = (payload) => ({
    type: actions.AT_GLOBAL_SUCCESS,
    action_type: actions.AT_GET_FOCUS_ZONES_LIST,
    payload,
});

export const getFocusZoneListRequestAction = (payload) => ({
    type: actions.AT_GLOBAL_REQUEST,
    action_type: actions.AT_GET_FOCUS_ZONES_LIST,
    payload,
});

// ===================

export const getFocusZoneListLibraryFailureAction = (payload) => ({
    type: actions.AT_GLOBAL_FAILURE,
    action_type: actions.AT_GET_FOCUS_ZONES_LIBRARY_LIST,
    payload,
});

export const getFocusZoneListLibrarySuccessAction = (payload) => ({
    type: actions.AT_GLOBAL_SUCCESS,
    action_type: actions.AT_GET_FOCUS_ZONES_LIBRARY_LIST,
    payload,
});

export const getFocusZoneLibraryListRequestAction = (payload) => ({
    type: actions.AT_GLOBAL_REQUEST,
    action_type: actions.AT_GET_FOCUS_ZONES_LIBRARY_LIST,
    payload,
});


// ===================

export const getFocusZoneVideoListLibraryFailureAction = (payload) => ({
    type: actions.AT_GLOBAL_FAILURE,
    action_type: actions.AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST,
    payload,
});

export const getFocusZoneVideoListLibrarySuccessAction = (payload) => ({
    type: actions.AT_GLOBAL_SUCCESS,
    action_type: actions.AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST,
    payload,
});

export const getFocusZoneVideoLibraryListRequestAction = (payload) => ({
    type: actions.AT_GLOBAL_REQUEST,
    action_type: actions.AT_GET_FOCUS_ZONES_VIDEO_LIBRARY_LIST,
    payload,
});

