const initState = {
    message: "Hello you!",
};

const myReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
    }
};

export default myReducer;
